import React from 'react';
import styled from 'styled-components/macro';
import LogoBobler from './LogoBobler';
import Div from '../../../../../components/Div';

const LogoLink = styled(Div)`
  color: ${({ theme }) => theme.colors.colorBlue};
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-right: 40px;

  span {
    display: block;
    font-family: 'Inter', sans-serif;
  }
`;
const LogoText1 = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 3.5px;
`;
const LogoText2 = styled.span`
  font-weight: 500;
  font-size: 13.7px;
`;
const SkafteBobler = styled(LogoBobler)`
  position: absolute;
  right: 0;
  width: auto;
  height: 37px;
  top: 5px;

  @media (max-width: 575px) {
    display: none;
  }
`;

export default ({
  title,
  text1,
  text2,
  responsive,
  ...props
}) => (
  <LogoLink responsive={responsive} title={title} {...props}>
    <LogoText1 responsive={responsive}>
      {text1}
    </LogoText1>
    <LogoText2>
      {text2}
    </LogoText2>
    <SkafteBobler responsive={responsive} />
  </LogoLink>
);

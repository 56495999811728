import React from "react";
import styled from "styled-components/macro";

export const TabButtonWrapNoStyle = ({ children, ...otherProps }) => (
  <div {...otherProps}>{children}</div>
);
export const TabButtonWrap = styled(TabButtonWrapNoStyle)`
  border-bottom: 10px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  overflow: hidden;
  padding-top: 5px;
  @media (max-width: 991px) {
    border-bottom: 0px !important;
  }
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const TabFlexDiv = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 1199px) {
    gap: 2px;
  }

  @media (max-width: 991px) {
  }
`;

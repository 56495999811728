import loadable from "@loadable/component";
import itemRoutes from "./item-routes";
import { deliveryTypes } from "../features/commerce/delivery/shared";
import {
  HomeBC,
  SodavandBC,
  KildevandBC,
  DanskeFadoelBC,
  FadoelBC,
  UdenlandskeFadoelBC,
  FaqBC,
  FlaskerOgDaaserBC,
  OelBC,
  FlaskeOelBC,
  VideoerBC,
  FadoelPilsnerBC,
  FadoelClassicBC,
  FadoelIpaBC,
  FadoelAleBC,
  FadoelBrownAleBC,
  FadoelLysAleBC,
  FadoelDarkAleBC,
  FadoelPorterBC,
  FadoelLetoelBC,
  FadoelHvedeBC,
  FadoelJuleoelBC,
  FadoelModular20BC,
  FadoelFlex20BC,
  FadoelsanlaegBC,
  ReservedeleBC,
  ReservedeleKulsyreTilkoblingBC,
  ReservedeleOelTilkoblingBC,
  ReservedeleOelAftapningBC,
  ReservedeleKoeleanlaegBC,
  AlkoholFriOelBC,
  AlkoholSodavandBC,
  VinBC,
  KulsyreBC,
  FadoelsBarBC,
} from "../layout/DefaultLayout/Header/components/Breadcrumbs/BreadcrumbsList";
import KunneIkkeHenteRute from "./KunneIkkeHenteRute";
import HenterRute from "./HenterRute";
import FlaskerOgDaaserTabs from "../pages/Bestil/FlaskerOgDaaser/Tabs";
import FadoelOgAnlaegTabs from "../pages/Bestil/FadoelOgAnlaeg/Tabs";
import KulsyreTabs from "../pages/Bestil/Kulsyre/Tabs";

// export const loadable = component => loadable(() => component());

export const MainBestilLayout = loadable(() =>
  import("../pages/Bestil/layout/MainBestilLayout"));
export { KunneIkkeHenteRute, HenterRute };

const flaskerOgDaaserProps = {
  tabComponent: FlaskerOgDaaserTabs,
  step: 1,
  preferredDeliveryType: deliveryTypes.delivery,
};
const fadoelOgAnlaegProps = {
  tabComponent: FadoelOgAnlaegTabs,
  step: 1,
  preferredDeliveryType: deliveryTypes.no,
};
const kulsyreProps = {
  tabComponent: KulsyreTabs,
  step: 1,
  preferredDeliveryType: deliveryTypes.no,
};
const step1Props = {
  step: 1,
};
const step2Props = {
  step: 2,
};
const step3Props = {
  step: 3,
};

const routes = [
  {
    path: "/",
    component: loadable(() => import("../pages/Home")),
    breadcrumbs: {
      base: [HomeBC],
    },
    meta: {
      title: "Skafte - Salg og levering af sodavand, kildevand og fadøl",
      description:
        "Køb øl og vand hos Skafte. Vi leverer overalt i Storkøbenhavn og omegn. Slip for besværet, vi ruller kasserne på plads, fylder op og tager de tomme med.",
    },
  },
  /*  BESTIL: special routes */
  {
    path: "/bestil",
    redirectTo: "/bestil/flasker-og-daaser",
    bestilLayout: true,
    bestilProps: flaskerOgDaaserProps,
    goToTopKey: "bestil",
    disableAnalytics: true,
    disableBreadCrumbsSchema: true,
    meta: {
      canonical: "/kildevand",
    },
  },
  {
    path: "/bestil/flasker-og-daaser",
    redirectTo: "/bestil/flasker-og-daaser/kildevand",
    redirectToWithPlejerliste: "/bestil/flasker-og-daaser/saedvanlige",
    bestilLayout: true,
    bestilProps: flaskerOgDaaserProps,
    disableBreadCrumbsSchema: true,
    goToTopKey: "bestil",
    disableAnalytics: true,
    meta: {
      canonical: "/kildevand",
    },
  },
  {
    path: "/bestil/fadoel-og-anlaeg",
    redirectTo: "/bestil/fadoel-og-anlaeg/fadoel",
    bestilLayout: true,
    bestilProps: fadoelOgAnlaegProps,
    disableBreadCrumbsSchema: true,
    goToTopKey: "bestil",
    disableAnalytics: true,
    search: {
      triggers: ["fustager"],
      image: "produkter-fadoel.carlsberg-fustage.png",
      title: "Stort sortiment af fustager",
      description:
        "Vi har mere end 100 forskellige slags fustager. Se mere her..",
      meta: {
        canonical: "/fadoelsanlaeg",
      },
    },
  },
  {
    path: "/bestil/:pageName/:tabName/kategori/:category/vare/:varenr",
    component: loadable(() => import("../pages/Bestil/RedirectVare")),
    bestilLayout: true,
    bestilProps: step1Props,
    disableBreadCrumbsSchema: true,
    goToTopKey: "bestil",
    disableAnalytics: true,
  },
  {
    path: "/bestil/:pageName/kategori/:category/vare/:varenr",
    component: loadable(() => import("../pages/Bestil/RedirectVare")),
    bestilLayout: true,
    bestilProps: step1Props,
    disableBreadCrumbsSchema: true,
    goToTopKey: "bestil",
    disableAnalytics: true,
  },
  {
    path: "/bestil/:pageName/:tabName/kategori/:category",
    component: loadable(() => import("../pages/Bestil/RedirectCategory")),
    bestilLayout: true,
    bestilProps: step1Props,
    disableBreadCrumbsSchema: true,
    goToTopKey: "bestil",
    disableAnalytics: true,
  },
  {
    path: "/bestil/:pageName/kategori/:category",
    component: loadable(() => import("../pages/Bestil/RedirectCategory")),
    bestilLayout: true,
    bestilProps: step1Props,
    disableBreadCrumbsSchema: true,
    goToTopKey: "bestil",
    disableAnalytics: true,
  },
  {
    path: "/bestil/ordre/accept/:orderRef",
    component: loadable(() => import("../pages/Bestil/RedirectOrdreAccept")),
    bestilLayout: true,
    bestilProps: step2Props,
    disableBreadCrumbsSchema: true,
    disableAnalytics: true,
  },
  /* BESTIL: Flaske og daaser */
  {
    path: "/bestil/flasker-og-daaser/saedvanlige",
    component: loadable(() =>
      import("../pages/Bestil/FlaskerOgDaaser/Saedvanlige")),
    bestilLayout: true,
    disableBreadCrumbsSchema: true,
    bestilProps: {
      ...flaskerOgDaaserProps,
      activeTab: "/bestil/flasker-og-daaser/saedvanlige",
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Sædvanlige",
      description: "",
    },
  },
  {
    path: "/bestil/flasker-og-daaser/kildevand",
    disableBreadCrumbsSchema: true,
    component: loadable(() =>
      import("../pages/Bestil/FlaskerOgDaaser/Kildevand")),
    bestilLayout: true,
    bestilProps: {
      ...flaskerOgDaaserProps,
      activeTab: "/bestil/flasker-og-daaser/kildevand",
    },
    goToTopKey: "bestil",
    search: {
      triggers: ["bestil", "flasker", "dåser"],
      image: "bestil.flaskerogdaaser1.png",
      title: "Bestil Flasker & Dåser",
      description: "Gå til nem bestilling af Flasker & Dåser",
    },
    meta: {
      title: "Skafte.dk | Bestil | Kildevand",
      description: "",
      canonical: "/kildevand",
    },
    moms: false,
  },
  {
    path: "/bestil/flasker-og-daaser/sodavand",
    disableBreadCrumbsSchema: true,
    component: loadable(() =>
      import("../pages/Bestil/FlaskerOgDaaser/Sodavand")),
    bestilLayout: true,
    bestilProps: {
      ...flaskerOgDaaserProps,
      activeTab: "/bestil/flasker-og-daaser/sodavand",
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Sodavand",
      description: "",
      canonical: "/sodavand",
    },
    moms: false,
  },
  {
    path: "/bestil/flasker-og-daaser/juice-og-saft",
    disableBreadCrumbsSchema: true,
    component: loadable(() =>
      import("../pages/Bestil/FlaskerOgDaaser/JuiceOgSaft")),
    bestilLayout: true,
    noSitemap: true,
    bestilProps: {
      ...flaskerOgDaaserProps,
      activeTab: "/bestil/flasker-og-daaser/juice-og-saft",
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Juice & saft",
      description: "",
      canonical: "/juice",
    },
    moms: false,
  },
  {
    path: "/bestil/flasker-og-daaser/oel-og-alkohol",
    disableBreadCrumbsSchema: true,
    component: loadable(() =>
      import("../pages/Bestil/FlaskerOgDaaser/OelOgAlkohol")),
    bestilLayout: true,
    noSitemap: true,
    bestilProps: {
      ...flaskerOgDaaserProps,
      activeTab: "/bestil/flasker-og-daaser/oel-og-alkohol",
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Øl & alkohol",
      description: "",
      canonical: "/oel",
    },
    moms: false,
  },
  {
    path: "/bestil/flasker-og-daaser/levering",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/Levering")),
    bestilLayout: true,
    bestilProps: {
      ...flaskerOgDaaserProps,
      activeTab: "/bestil/flasker-og-daaser/levering",
    },
    noSitemap: true,
    search: {
      triggers: [
        "fragt",
        "retur",
        "returnering",
        "retunering",
        "mandag",
        "tirsdag",
        "onsdag",
        "torsdag",
        "fredag",
        "lørdag",
        "søndag",
        "nat",
        "aften",
      ],
      image: "levering.skafte-levering-kasser-60px.jpg",
      title: "Levering & afhentning",
      description: "Læs mere om vores leverings- og afhentningsmuligheder.",
    },
    meta: {
      title: "Skafte.dk | Bestil | Levering",
      description: "",
      canonical: "/levering-afhentning",
    },
  },
  /* BESTIL: Fadøl og anlæg */
  {
    path: "/bestil/fadoel-og-anlaeg/fadoel",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/FadoelOgAnlaeg/Fadoel")),
    bestilLayout: true,
    bestilProps: {
      ...fadoelOgAnlaegProps,
      activeTab: "/bestil/fadoel-og-anlaeg/fadoel",
    },
    search: {
      triggers: ["bestil", "anlæg"],
      image: "bestil.fadoeloganlaeg1.png",
      title: "Bestil Fadøl & Anlæg",
      description: "Gå til nem bestilling af fadøl",
      subpageTriggers: [["tilbud", "/kategori/tilbud-fadoel"]],
      subpageData: {
        "/kategori/tilbud-fadoel": {
          title: "Fadøl på tilbud",
          image: "produkter-fadoel.beregner-kurv.png",
          description:
            "Beregn hvor mange fustager og fadølshaner du skal bruge",
        },
      },
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Fadøl",
      description: "",
      canonical: "/fadoel",
    },
  },
  {
    path: "/bestil/fadoel-og-anlaeg/anlaeg-og-bar",
    disableBreadCrumbsSchema: true,
    component: loadable(() =>
      import("../pages/Bestil/FadoelOgAnlaeg/AnlaegOgBar")),
    bestilLayout: true,
    bestilProps: {
      ...fadoelOgAnlaegProps,
      activeTab: "/bestil/fadoel-og-anlaeg/anlaeg-og-bar",
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Anlæg og bar",
      description: "",
      canonical: "/fadoelsanlaeg",
    },
    search: {
      triggers: ["fadølshane", "fadølsanlæg", "tappehane"],
      image: "bestil.fadoeloganlaeg1.png",
      title: "Bestil fadølshane og anlæg",
      description: "Gå til nem bestilling af anlæg",
      subpageTriggers: [
        ["beregner", "#fadoelsberegner"],
        ["fadølsberegner", "#fadoelsberegner"],
        ["fadølberegner", "#fadoelsberegner"],
        ["udregner", "#fadoelsberegner"],
        ["fadølsudregner", "#fadoelsberegner"],
        ["fadoelsudregner", "#fadoelsberegner"],
        ["fadolsudregner", "#fadoelsberegner"],
        ["fadølsberegning", "#fadoelsberegner"],
        ["beregning", "#fadoelsberegner"],
      ],
      subpageData: {
        "#fadoelsberegner": {
          title: "Fadølsberegner",
          image: "style.beregner.png",
          description:
            "Beregn hvor mange fustager og fadølshaner du skal bruge",
        },
      },
    },
  },
  {
    path: "/bestil/fadoel-og-anlaeg/oelglas",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/FadoelOgAnlaeg/OelGlas")),
    bestilLayout: true,
    bestilProps: {
      ...fadoelOgAnlaegProps,
      activeTab: "/bestil/fadoel-og-anlaeg/oelglas",
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Ølglas",
      description: "",
      canonical: "/oelglas",
    },
  },
  {
    path: "/bestil/fadoel-og-anlaeg/reservedele",
    disableBreadCrumbsSchema: true,
    component: loadable(() =>
      import("../pages/Bestil/FadoelOgAnlaeg/Reservedele")),
    bestilLayout: true,
    bestilProps: {
      ...fadoelOgAnlaegProps,
      activeTab: "/bestil/fadoel-og-anlaeg/reservedele",
    },
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Reservedele",
      description: "",
      canonical: "/reservedele",
    },
    search: {
      triggers: ["reservedele", "micro matic"],
      image: "reservedele.trykregulator-uden-studs-stor.png",
      title: "Reservedele til fadølsanlæg",
      description:
        "Rensning - Kulsyre tilkobling - øl tilkobling - øl aftapning - køleanlæg",
      subpageTriggers: [
        ["koblinger", "/kategori/oel-tilkobling"],
        ["ølkobling", "/kategori/oel-tilkobling"],
      ],
      subpageData: {
        "/kategori/oel-tilkobling": {
          title: "Øl tilkobling",
          image: "reservedele.fadkobling-s.png",
          description: "Fadkoblinger - Studser - Tilbageløbspakning m.m.",
        },
      },
    },
  },
  {
    path: "/bestil/fadoel-og-anlaeg/levering",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/Levering")),
    bestilLayout: true,
    bestilProps: {
      ...fadoelOgAnlaegProps,
      activeTab: "/bestil/fadoel-og-anlaeg/levering",
    },
    noSitemap: true,
    meta: {
      title: "Skafte.dk | Bestil | Levering",
      description: "",
      canonical: "/levering-afhentning",
    },
  },

  /* BESTIL: diverse */
  {
    path: "/bestil/detaljer",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/Detaljer")),
    bestilLayout: true,
    bestilProps: step2Props,
    meta: {
      title: "Skafte.dk | Bestil | Detaljer",
      description: "",
    },
  },
  {
    path: "/bestil/soeg",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/SearchPage")),
    bestilLayout: true,
    noSitemap: true,
    bestilProps: step1Props,
    goToTopKey: "bestil",
    meta: {
      title: "Skafte.dk | Bestil | Søg",
      description: "",
    },
  },
  {
    path: "/bestil/ordre/:orderRef",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/OrderComplete")),
    bestilLayout: true,
    bestilProps: step3Props,
    noSitemap: true,
    meta: {
      title: "Skafte.dk | Bestil | Ordre",
      description: "",
    },
  },
  {
    path: "/bestil/returpant",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/ReturPantPage")),
    bestilLayout: true,
    bestilProps: step1Props,
    search: {
      triggers: ["retur", "returvarer", "retur varer", "pantkasse"],
      image: "returpant.cola-kasse.png",
      title: "Returpant",
      description:
        "Oplys om dit returpant her - Du behøver ikke at oplyse returpant.",
    },
    meta: {
      title: "Skafte.dk | Bestil | Returpant",
      description: "",
    },
  },
  {
    path: "/bestil/kulsyre",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/Kulsyre/Kulsyre")),
    bestilLayout: true,
    bestilProps: {
      ...kulsyreProps,
      activeTab: "/bestil/kulsyre",
    },
    meta: {
      title: "Skafte.dk | Bestil | Kulsyre",
      description: "",
      canonical: "/kulsyre",
    },
  },
  {
    path: "/bestil/kulsyre/levering",
    disableBreadCrumbsSchema: true,
    component: loadable(() => import("../pages/Bestil/Levering")),
    bestilLayout: true,
    bestilProps: {
      ...kulsyreProps,
      activeTab: "/bestil/kulsyre/levering",
    },
    noSitemap: true,
    meta: {
      title: "Skafte.dk | Bestil | Levering",
      description: "",
      canonical: "/levering-afhentning",
    },
  },
  /* SIDER */

  /* FADØL */
  {
    path: "/fadoel",
    component: loadable(() => import("../pages/fadoel/Fadoel")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC],
    },
    meta: {
      title: "Fadøl på fustage - Oplev et kæmpe smagsudvalg hos Skafte",
      description:
        "Bestil over 100+ varianter af fadøl til din næste fest. Stort udvalg af Classic, Ale, IPA og juleøl. Ubrudte fustager tages retur. Bestil fadøl hos Skafte!",
    },
  },
  {
    path: "/fadoel/pilsner",
    component: loadable(() => import("../pages/fadoel/Pilsner")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelPilsnerBC],
    },
    meta: {
      title: "Pilsner - Bestil lækker, forfriskende øl til fadølsanlægget",
      description:
        "Skafte er din leverandør af pilsner på fad. Vælg mellem flere velsmagende pilsnere, herunder dansk pilsner som Tuborg og Carlsberg - Se hele udvalget her!",
    },
  },
  {
    path: "/fadoel/classic",
    component: loadable(() => import("../pages/fadoel/Classic")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelClassicBC],
    },
    search: {
      triggers: ["carlsberg", "tuborg", "classic", "klassik", "klassisk"],
      image: "produkter-fadoel.tuborg-classic.png",
    },
    meta: {
      title: "Classic øl - Få leveret fustager og anlæg mv. til næste fest",
      description:
        "Server iskold & forfriskende Classic øl til dit næste arrangement. Den mørke Classic pilsner er altid et hit hos danskerne - Se hele udvalget & bestil her.",
    },
  },
  {
    path: "/fadoel/ipa",
    component: loadable(() => import("../pages/fadoel/IPA")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelIpaBC],
    },
    meta: {
      title: "IPA - Bestil IPA øl (India Pale Ale) til fadølsanlægget her",
      description:
        "IPA i fadølshanerne er altid et hit blandt gæsterne. Hos Skafte finder du et bredt udvalg af velsmagende IPA øl (India Pale Ale) - Se priser og udvalg her.",
    },
  },
  {
    path: "/fadoel/ale",
    component: loadable(() => import("../pages/fadoel/Ale")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelAleBC],
    },
    meta: {
      title: "Ale - Stort udvalg af Ale øl på fustage til skarpe priser",
      description:
        "Få en spændende ale i fadølshanerne - Vi er DK’s bedste leverandør af både anlæg og øl, herunder ale øl, i Storkøbenhavn og omegn - Se priser & udvalg her!",
    },
  },
  {
    path: "/fadoel/ale/brown-ale",
    component: loadable(() => import("../pages/fadoel/BrownAle")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelAleBC, FadoelBrownAleBC],
    },
    meta: {
      title: "Brown Ale fadøl",
      description: "Køb Brown Ale som fadøl hos skafte.dk",
    },
  },
  {
    path: "/fadoel/ale/lys-ale",
    component: loadable(() => import("../pages/fadoel/LysAle")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelAleBC, FadoelLysAleBC],
    },
    meta: {
      title: "Lys Ale fadøl",
      description: "Køb Lys Ale som fadøl hos skafte.dk",
    },
  },
  {
    path: "/fadoel/ale/moerk-ale",
    component: loadable(() => import("../pages/fadoel/MoerkAle")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelAleBC, FadoelDarkAleBC],
    },
    meta: {
      title: "Mørk Ale fadøl",
      description: "Køb Mørk Ale som fadøl hos skafte.dk",
    },
  },
  {
    path: "/fadoel/porter",
    component: loadable(() => import("../pages/fadoel/Porter")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelPorterBC],
    },
    meta: {
      title: "Porter og Stout - Bestil lækker mørk fadøl til arrangementet",
      description:
        "Forkæl gæsterne med porter & stout i fadølshanerne. Den fyldige øl egner sig særligt godt til langtidsbraiserede kødretter & desserter. 1-2 dages levering.",
    },
  },
  {
    path: "/fadoel/letoel",
    component: loadable(() => import("../pages/fadoel/LetOel")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelLetoelBC],
    },
    meta: {
      title: "Letøl | Alkoholfri Fadøl til festen - Bestil fustager her",
      description:
        "Bestil smagfuld letøl til dem foretrækker alkoholfri fadøl. Vælg blandt kendte mærker og få nemt leveret og afhentet både øl og anlæg uden besvær.",
    },
  },
  {
    path: "/fadoel/hvedeoel",
    component: loadable(() => import("../pages/fadoel/Hvedeoel")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelHvedeBC],
    },
    meta: {
      title: "Hvedeøl på fad - Køb weissbier til festen med alt inkluderet",
      description:
        "Hvedeøl i fadølshanerne giver glade gæster. Vælg bl.a. mellem dansk, belgisk og tysk hvedeøl. Skafte leverer det hele til døren - Bestil øl og anlæg her.",
    },
  },
  {
    path: "/fadoel/juleoel",
    component: loadable(() => import("../pages/produkter-fadoel/Julefrokost")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelJuleoelBC],
    },
    meta: {
      title: "Juleøl til fadølsanlægget - Bestil og få leveret til døren!",
      description:
        "Juleøl på fad er et must til julefrokosten. Vælg mellem flere julebryg, bl.a. Tuborg, Skovlyst og Anarkist! Få et professionelt setup med anlæg, borde mv.",
    },
  },
  {
    path: "/fadoel/oktoberfest",
    component: loadable(() => import("../pages/fadoel/Oktoberfest")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC],
      title: "Oktoberfest",
    },
    search: {
      triggers: ["oktoberfest"],
      image: "produkter-fadoel.paulaner-oktober-fest.png",
    },
    meta: {
      title: "Oktoberfest Øl i alle afskygninger på fad >> Bestil nu!",
      description:
        "Ægte traditionstro oktoberfest øl, fx Paulaner, Erdinger Hefe Weissbier eller Erdinger Dunkel Weissbier. Bestil alt i øl, udstyr og anlæg hos Skafte.dk.",
    },
  },
  {
    path: "/fadoel/paaskebryg",
    component: loadable(() => import("../pages/fadoel/Paaskefrokost")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC],
      title: "Påskebryg",
    },
    meta: {
      title: "Påskebryg - Bestil påskeøl på fad til jeres påskefrokost her",
      description:
        "Køb fustager med påskebryg og glæd alle til påskefesten. Vi leverer alle slags påskeøl samt kulsyre, fadølsanlæg, glas og mere - Altid hurtig levering!",
    },
  },

  {
    path: "/fadoel/udenlandske-oel-paa-fad",
    component: loadable(() =>
      import("../pages/produkter-fadoel/UdenlandskeFadoel")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, UdenlandskeFadoelBC],
    },
    meta: {
      title: "Udenlandsk Øl - Bestil spændende smagsoplevelser på fad",
      description:
        "Glæd gæsterne til festen med lækker udenlandsk øl der kilder smagsløgende. Vi tilbyder alle de populære mærker og leverer alt i anlæg og udstyr til jer.",
    },
  },
  {
    path: "/fadoel/dansk-fadoel",
    component: loadable(() => import("../pages/fadoel/DanskeFadoel")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, DanskeFadoelBC],
    },
    meta: {
      title: "Dansk fadøl | Køb de klassiske samt øl fra mindre bryghuse",
      description:
        "Bestil fadøl fra Tuborg, Carlsberg & Royal Unibrew men også spændende minibrygger-øl som Skovlyst, Wiibroe, Albani & Refsvindinge Bryggeri. → Se udvalget.",
    },
  },
  {
    path: "/fadoel/priser",
    component: loadable(() => import("../pages/PriserFadoel")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC],
      title: "Fadølspriser",
    },
    search: {
      triggers: ["priser"],
      image: "produkter-fadoel.groen-tuborg.png",
    },
    meta: {
      title: "De skarpeste fadøl priser finder du hos Skafte - Køb her!",
      description:
        "Få et overblik over fadøl priser - Vi leverer og opstiller fadølsanlæg - Skafte har altid billig fadøl på lager - Bestil nu og få hurtig levering!",
    },
  },
  {
    path: "/fadoel/modular-20",
    component: loadable(() => import("../pages/fadoel/Modular20")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelModular20BC],
      title: "Modular 20",
    },
    meta: {
      title: "Modular 20 plastikfustager - Se udvalget af fadøl hos Skafte",
      description:
        "Vi fører et udvalg af Modular 20 fustager, som tages hjem på bestilling. Vælg mellem flere fadølsvarianter, såsom Tuborg, Carlsberg & Grimbergen - Køb her!",
    },
  },
  {
    path: "/fadoel/flex-20",
    component: loadable(() => import("../pages/fadoel/Flex20")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC, FadoelFlex20BC],
      title: "Flex 20",
    },
    meta: {
      title: "Flex 20 plastikfustager - Se Skaftes udvalg af fadøl her",
      description:
        "Vi fører et mindre udvalg af Flex 20 fustager, som tages hjem på bestilling. Vælg blandt de kendte fadølsvarianter som Carlsberg & Tuborg - Køb online her!",
    },
  },
  {
    path: "/fadoel/cocktails-vin-cider",
    component: loadable(() => import("../pages/fadoel/CocktailsVinCider")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC],
      title: "Cocktails, vin & cider",
    },
    meta: {
      title: "Cocktails, vin & cider",
      description:
        "Få lækre opblandede cocktails direkte ud af fadølshanen eller måske var det noget med mousserende hvidvin eller god cider.",
    },
  },

  /* FADØLSANLÆG */

  {
    path: "/fadoelsanlaeg",
    component: loadable(() => import("../pages/fadoelsanlaeg/Fadoelsanlaeg")),
    breadcrumbs: {
      base: [HomeBC, FadoelsanlaegBC],
    },
    meta: {
      title: "Lej fadølsanlæg - Professionelt setup med ølhaner og kulsyre",
      description:
        "Lej et professionelt fadølsanlæg til din fest. Komplet setup med bar, køleanlæg og kulsyre. Perfekt til private og firmafester. Bestil nemt hos Skafte nu!",
    },
  },
  {
    path: "/fadoelsanlaeg/opsaetning-af-fadoelsanlaeg",
    component: loadable(() =>
      import("../pages/fadoelsanlaeg/OpsaetningAfFadoelsanlaeg")),
    breadcrumbs: {
      base: [HomeBC, FadoelsanlaegBC],
      title: "Opsætning af fadølsanlæg",
    },
    search: {
      subpageTriggers: [
        ["opsætning", "#opsaetning-af-fadoelsanlaeg"],
        ["montering", "#opsaetning-af-fadoelsanlaeg"],
      ],
      subpageData: {
        "#opsaetning-af-fadoelsanlaeg": {
          title: "Opsætning af fadølsanlæg",
          image: "video.opsaetning-fadoelsanlaeg-komplet.jpg",
          description:
            "Se hvordan du opsætter fadølsanlægget og tilslutter kulsyre og fustager",
        },
      },
    },
    meta: {
      title: "Opsætning af fadølsanlæg - sådan sættes anlægget korrekt op",
      description:
        "Guide til opsætning af fadølsanlæg samt gode råd - Fadølsanlæg opsætning er en simpel, men nødvendig opgave for at kunne skænke en god øl - Se videoen her!",
    },
  },
  {
    path: "/fadoelsanlaeg/tilslutning-af-fadoelsanlaeg",
    component: loadable(() =>
      import("../pages/fadoelsanlaeg/TilslutningAfFadoelsanlaeg")),
    breadcrumbs: {
      base: [HomeBC, FadoelsanlaegBC],
      title: "Tilslutning af fadølsanlæg, fadøl og kulsyre",
    },
    meta: {
      title: "Tilslutning af fadølsanlæg, fadøl og kulsyre - sådan gør du",
      description:
        "Korrekt tilslutning af fadølsanlæg, kulsyre og fustage sikrer den bedste oplevelse og smag. Få guiden til hvordan du får anlægget til at virke - Se video!",
    },
  },
  {
    path: "/fadoelsanlaeg/vandkoelet-fadoelsanlaeg",
    component: loadable(() =>
      import("../pages/fadoelsanlaeg/VandkoeletFadoelsanlaeg")),
    breadcrumbs: {
      base: [HomeBC, FadoelsanlaegBC],
      title: "Vandkølet fadølsanlæg",
    },
    meta: {
      title: "Vandkølet fadølsanlæg - få gode råd til opsætning og drift",
      description:
        "Sådan fungerer et vandkølet fadølsanlæg. Hos Skafte har vi styr på alt hvad angår fadøl og anlæg, og vi guider dig til optimal brug. Læs mere og lej her!",
    },
  },
  {
    path: "/fadoelsanlaeg/saadan-tapper-du-den-perfekte-fadoel",
    component: loadable(() =>
      import("../pages/fadoelsanlaeg/SaadanTapperDuPerfektFadoel")),
    breadcrumbs: {
      base: [HomeBC, FadoelsanlaegBC],
      title: "Sådan tapper du den perfekte fadøl",
    },
    meta: {
      title: "Vandkølet fadølsanlæg - få gode råd til opsætning og drift",
      description:
        "Det er en kunst at tappe den perfekte fadøl. Hos Skafte finder du en guide til hvordan du altid kan nyde en godt skænket fadøl i dit glas - Læs mere her!",
    },
  },

  /* FADØLSBAR */
  {
    path: "/fadoelsbar",
    component: loadable(() =>
      import("../pages/produkter-fadoel/BarTilFadoelsanlaeg")),
    breadcrumbs: {
      base: [HomeBC, FadoelsBarBC],
    },
    meta: {
      title: "Bar til fadølsanlæg udlejes her!",
      description:
        "Vi har flotte barer, der let kan samles og står meget robust. Fås både som bardisk, samt stilladsbar.",
    },
  },

  /* FADØLSGLAS */
  {
    path: "/oelglas",
    component: loadable(() => import("../pages/produkter-fadoel/FadoelsGlas")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Fadølsglas",
    },
    search: {
      triggers: ["tuborg glas", "glas", "fadølsglas"],
      image: "produkter-oelvand.7773-flaske.png",
    },
    meta: {
      title: "Fadølsglas i høj kvalitet - bedre end normale plastikglas",
      description:
        "Stort udvalg af plastik-ølglas, bærehanke og ølkander til din fest. Bestil fadølskrus i plast i 30 cl og 40 cl samt lej 1-liters ølglas til Oktoberfest!",
    },
  },

  /* Kulsyre */
  {
    path: "/kulsyre",
    component: loadable(() => import("../pages/kulsyre/Kulsyre")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Kulsyre",
    },
    search: {
      triggers: [
        "kulsyre",
        "co2",
        "kullsyre",
        "forbrug",
        "gas",
        "svejsning",
        "genopfyld",
        "kuldioxid",
        "kudioxcid",
        "kuldioxcid",
        "påfyldning",
      ],
      description:
        "Kulsyreflasker | Engros | Drikkevandskølere | Svejsning | Sodastream | Akvarie",
      image: "kulsyre.kulsyre-engros1.jpg",
      subpageTriggers: [["gevind på kulsyreflasker", "#gevind"]],
      subpageData: {
        "#gevind": {
          title: "Gevind på kulsyreflasker",
          image: "kulsyre.kulsyre-gevind.png",
          description:
            "Alle kulsyreflasker bruger samme gevind. Teknisk information og uddybning.",
        },
      },
    },
    meta: {
      title: "Kulsyre | Køb kulsyreflasker til multi-brug | 4 eller 6 kg",
      description:
        "Kulsyre har mange formål - køb kulsyreflakser til fadøl, akvarie, svejsning eller Sodastream her! Ombyt eller køb ny CO2 flaske - gratis fragt ved 2 stk.",
    },
  },
  {
    path: "/kulsyre/kulsyre-til-fadoel",
    component: loadable(() => import("../pages/kulsyre/KulsyreTilFadoel")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Kulsyre til Fadøl",
    },
    meta: {
      title: "Kulsyre til Fadøl | Køb billigt kulsyre til fadølsanlægget ",
      description:
        "Køb kulsyre til fadøl hos Skafte.dk - Vi er eksperter i fadøl og fustager. Køb store flasker og spar penge på kulsyre til fadøl + let guide til montering. ",
    },
  },
  {
    path: "/kulsyre/sodastream",
    component: loadable(() => import("../pages/kulsyre/KulsyreTilSodastream")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Kulsyre til sodastream",
    },
    search: {
      triggers: ["sodastream", "co2", "kullsyre", "kulsyre", "gas"],
      image: "kulsyre.kulsyreSodastream1.jpg",
    },
    meta: {
      title: "Kulsyre til Sodastream | 10x mere kulsyre end normal patron",
      description:
        "Billig Kulsyre til Sodastreamanlæg. Dit brusende vand/sodavand behøver ikke at være dyrt - køb en større flaske og spar på kulsyrepatronerne - Skafte.dk",
    },
  },
  {
    path: "/kulsyre/danskvandsanlaeg",
    component: loadable(() => import("../pages/kulsyre/KulsyreTilDanskvandsanlaeg")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Kulsyre til danskvandsanlæg",
    },
    search: {
      triggers: ["quooker", "quooker cube", "qoouker", "quker", "qooker", "qoker", "quoker", "qouker"],
      image: "kulsyre.4-kg-kulsyreflaske-roed-properSize.png",
    },
    meta: {
      title: "Kulsyre til danskvandsanlæg - Køb billig kulsyre til f.eks. Quooker Cube & Flex",
      description:
        "Skafte tilbyder et billigere alternativ til Quooker kulsyre, der giver mulighed for længere tids brug. Passer til både Quooker Cube og Flex - Se mere her.",
    },
  },
  {
    path: "/kulsyre/kulsyrebatterier",
    component: loadable(() => import("../pages/kulsyre/Kulsyrebatterier")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Kulsyrebatterier",
    },
    meta: {
      title: "Kulsyrebatterier",
      description: "",
    },
    moms: false,
  },
  {
    path: "/kulsyre/co2-akvarie",
    component: loadable(() => import("../pages/kulsyre/KulsyreCO2Akvarie")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Kulsyre til CO2 Akvarier",
    },
    meta: {
      title: "Kulsyre til Akvarie | Køb CO2 flaske til iltning af akvariet",
      description:
        "Køb Kulsyre til akvarie online. Kulsuren bruges til iltning af vandet, så planter og fisk har de bedste vilkår. Flasken tilsluttes dit CO2 akvarie anlæg.",
    },
  },
  {
    path: "/kulsyre/drikkevandskoeler",
    component: loadable(() =>
      import("../pages/kulsyre/KulsyreDrikkevandskoeler")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Kulsyre til drikkevandskøler",
    },
    meta: {
      title: "Kulsyre til CO2 svejser - Bestil billigt på Skafte.dk",
      description:
        "Køb Kulsyre til CO2 svejsning. Opnå mængderabat ved køb af flere flasker. Vælg mellem 6 kg eller 4 kg flasker. Bestil kulsyre til CO2 svejser online!",
    },
  },
  {
    path: "/kulsyre/co2-svejsning",
    component: loadable(() => import("../pages/kulsyre/KulsyreCO2Svejsning")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Kulsyre til CO2 svejsning",
    },
    meta: {
      title: "Billig kulsyre til CO2 svejsning",
      description: "Kulsyre til CO2 svejsning",
    },
  },
  {
    path: "/kulsyre/opfyldning-af-kulsyreflasker",
    component: loadable(() => import("../pages/kulsyre/KulsyreOpfyldning")),
    breadcrumbs: {
      base: [HomeBC, KulsyreBC],
      title: "Opfyldning af kulsyreflasker",
    },
    meta: {
      title: "Opfyldning af kulsyreflasker",
      description: "Opfyldning af kulsyreflasker",
    },
  },

  /* RESERVEDELE */

  {
    path: "/reservedele",
    component: loadable(() => import("../pages/reservedele/Reservedele")),
    breadcrumbs: {
      base: [HomeBC, ReservedeleBC],
    },
    meta: {
      title: "Fadølsanlæg reservedele - Find alt hvad du mangler her",
      description:
        "Køb fadølsanlæg reservedele - Find alt lige fra produkter til rens af anlæg, tilkoblinger, aftapning og køleanlæg. Bestil reservedele til fadølsanlæg her.",
    },
  },
  {
    path: "/reservedele/rensning-fadoelsanlaeg",
    component: loadable(() =>
      import("../pages/reservedele/RensningFadoelsanlaeg")),
    breadcrumbs: {
      base: [HomeBC, ReservedeleBC],
    },
    meta: {
      title: "Rensedunke og rensevæske til fadølsanlæg",
      description:
        "Salg af rensedunke og rensevæske til fadøl. Alt til rens og rengøring af fadølsanlæg sendes over hele landet. Kan også hentes i Brøndby.",
    },
    search: {
      triggers: ["rensning af fadøl", "resning", "rengøring"],
      title: "Sådan renser du dit eget fadølsanlæg",
      image: "rensedunk.rensedunk-2dunke-200x203.jpg",
    },
  },
  {
    path: "/reservedele/kulsyre-tilkobling",
    component: loadable(() => import("../pages/reservedele/KulsyreTilkobling")),
    breadcrumbs: {
      base: [HomeBC, ReservedeleBC, ReservedeleKulsyreTilkoblingBC],
    },
    meta: {
      title: "Reservedele til kulsyre",
      description:
        "Trykregulatorer - Manometre - Pakninger -Studser - Slanger - Spændebånd",
    },
  },
  {
    path: "/reservedele/oel-tilkobling",
    component: loadable(() => import("../pages/reservedele/OelTilkobling")),
    breadcrumbs: {
      base: [HomeBC, ReservedeleBC, ReservedeleOelTilkoblingBC],
    },
    meta: {
      title: "Reservedele til øltilkobling",
      description: "Fadkoblinger - Klemeringe - Pakninger - Ventiler",
    },
    search: {
      triggers: [
        "kobling",
        "anstikker",
        "s kobling",
        "s-kobling",
        "a kobling",
        "a-kobling",
        "u kobling",
        "u-kobling",
        "d-kobling",
        "d kobling",
        "s3-kobling",
        "s3 kobling",
        "m-kobling",
        "m kobling",
      ],
      image: "reservedele.fadkobling-s.png",
      subpageTriggers: [["keykeg", "#keykeg"]],
      subpageData: {
        "#keykeg": {
          title: "Keykeg",
          image: "fadoel.keykeg.png",
          description:
            "Vi sælger ikke nogen slags øl i Keykeg. Find mere info om keykegs her.",
        },
      },
    },
  },
  {
    path: "/reservedele/oel-aftapning",
    component: loadable(() => import("../pages/reservedele/OelAftapning")),
    breadcrumbs: {
      base: [HomeBC, ReservedeleBC, ReservedeleOelAftapningBC],
    },
    meta: {
      title: "Reservedele til ølaftapning",
      description:
        "Topringe og plastikskaller- Lynkoblinger - Slanger - Drypbakke",
    },
  },
  {
    path: "/reservedele/koeleanlaeg",
    component: loadable(() => import("../pages/reservedele/Koeleanlaeg")),
    breadcrumbs: {
      base: [HomeBC, ReservedeleBC, ReservedeleKoeleanlaegBC],
    },
    meta: {
      title: "Reservedele til køleanlæg",
      description: "Termostat og omrøremotorer",
    },
  },

  /* KØB ØL ONLINE */

  {
    path: "/oel",
    component: loadable(() => import("../pages/Alkohol/Oel")),
    breadcrumbs: {
      base: [HomeBC, OelBC],
    },
    meta: {
      title: "Køb Øl Online - Kæmpe udvalg af flaskeøl, dåseøl og fadøl",
      description:
        "Køb øl online til skarpe priser & få leveret direkte til døren! Vi har et stort udvalg af klassiske & specialøl - Bestil øl online med hurtig levering her!",
    },
    moms: false,
  },
  {
    path: "/oel/daaseoel",
    component: loadable(() => import("../pages/Alkohol/Daaseoel")),
    breadcrumbs: {
      base: [HomeBC, OelBC],
      title: "Dåseøl",
    },
    meta: {
      title: "Dåseøl - Køb øl på dåse til skarpe priser - Hurtig levering",
      description:
        "Køb dåseøl online og få leveret til døren! Billige dåseøl - klassikere som Carlsberg dåseøl & Tuborg dåseøl i rammer á 24 stk. Gratis levering fra 5 rammer!",
    },
    moms: false,
  },
  {
    path: "/oel/flaskeoel",
    component: loadable(() => import("../pages/Alkohol/FlaskeOel")),
    breadcrumbs: {
      base: [HomeBC, OelBC, FlaskeOelBC],
    },
    meta: {
      title: "Flaskeøl - Flere varianter af øl på flaske - Køb hos Skafte",
      description:
        "Flaskeøl i mange varianter! Om du ønsker en gammel kending som Tuborg Classic på flaske eller en anden variant, så leverer vi god service & lige til døren.",
    },
    moms: false,
  },
  {
    path: "/oel/alkoholfri-oel",
    component: loadable(() => import("../pages/Alkohol/AlkoholfriOel")),
    breadcrumbs: {
      base: [HomeBC, OelBC, AlkoholFriOelBC],
    },
    meta: {
      title: "Køb alkoholfri øl hos Skafte - den gode smag, uden promillen",
      description:
        "Bestil alkoholfri øl hos Skafte. Stort udvalg - Tuborg, Carlsberg, Heineken m.fl. Nyd en forfriskende øl uden alkohol eller med lav promille. Køb her!",
    },
    moms: false,
  },

  /* ALKOHOLSODAVAND */
  {
    path: "/alkoholsodavand",
    component: loadable(() => import("../pages/Alkohol/AlkoholSodavand")),
    breadcrumbs: {
      base: [HomeBC, OelBC, AlkoholSodavandBC],
    },
    meta: {
      title: "Alkoholsodavand - Stort udvalg af Breezer, Somersby & drinks",
      description:
        "Hos Skafte kan du købe alkoholsodavand til skarpe priser - Vælg mellem Breezers, Somersby cider og cocktails på flaske - Bestil i dag & få hurtig levering.",
    },
    moms: false,
  },

  /* VIN */
  {
    path: "/vin",
    component: loadable(() => import("../pages/Alkohol/Vin")),
    breadcrumbs: {
      base: [HomeBC, OelBC, VinBC],
    },
    meta: {
      title: "Køb vin hos Skafte Øl & Vand - Vælg mellem rødvin og vidvin",
      description: "Rødvin | Hvidvin til dit arrangement",
    },
    moms: false,
  },

  /* SODAVAND */
  {
    path: "/sodavand",
    component: loadable(() => import("../pages/sodavand/Sodavand")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
    },
    meta: {
      title: "Køb Sodavand Online | Lave priser og stort udvalg - Skafte.dk",
      description:
        "Køb sodavand og få leveret lige til døren • Dag-til-dag levering i Storkøbenhavn og omegn • Fås i glas- plastikflaske eller dåse • Køb sodavand online nu.",
    },
    moms: false,
  },
  {
    path: "/sodavand/coca-cola",
    component: loadable(() => import("../pages/sodavand/CocaCola")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "Coca-Cola",
    },
    meta: {
      title: "Coca Cola | Køb nemt dine forfriskninger fra Coca Cola Online!",
      description:
        "Køb Coca Cola online til gode priser – vi leverer både til dig som privatperson, til din fest eller virksomhed. Vi fører alle klassikerne fra Coca Cola!",
    },
    moms: false,
  },
  {
    path: "/sodavand/frem-sodavand",
    component: loadable(() => import("../pages/sodavand/FremSodavand")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "Frem Sodavand",
    },
    search: {
      triggers: ["frem forhandler", "harboe"],
      image: "search.frem-sodavand.png",
      title: "Frem Sodavand",
    },
    meta: {
      title: "Frem Sodavand - God, dansk sodavand - Køb online hos Skafte",
      description:
        "Frem Sodavand i mange skønne smagsvarianter, prøv bl.a. abrikos, citron og rød sodavand - Skafte er officiel Frem sodavand forhandler - Hurtig levering.",
    },
    moms: false,
  },
  {
    path: "/sodavand/san-pellegrino-sodavand",
    component: loadable(() =>
      import("../pages/sodavand/SanPellegrinoSodavand")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "San Pellegrino",
    },
    meta: {
      title: "San Pellegrino Sodavand - Sodavand fra Italien",
      description: "",
    },
    moms: false,
  },
  {
    path: "/sodavand/schweppes",
    component: loadable(() => import("../pages/sodavand/Schweppes")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "Schweppes",
    },
    search: {
      triggers: ["schweppes", "bitter", "lemon"],
      image: "produkter-oelvand.schweppes-lemon-fusion-50cl.png",
      title: "Schweppes",
    },
    meta: {
      title: "Schweppes | Køb varianterne med Lemon, Tonic og Ginger Ale",
      description:
        "Schweppes er en ægte klassisker - Hvad enten du er til Ginger Ale, Tonic eller Lemon, kan du bestille dem ➔ lige her ✓Fri levering ved køb af 5 kasser.",
    },
    moms: false,
  },
  {
    path: "/sodavand/faxe-kondi",
    component: loadable(() => import("../pages/sodavand/FaxeKondi")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "Faxe Kondi",
    },
    meta: {
      title:
        "Faxe Kondi | Køb din favorit på glasflaske, plast eller dåse - her!",
      description:
        "Faxe Kondi er en sand klassiker, og er en læskedrik, der nydes af langt de fleste! Køb de populære Faxe Kondi på flaske eller dåse hos os på Skafte.dk nu!",
    },
    moms: false,
  },
  {
    path: "/sodavand/tuborg-squash",
    component: loadable(() => import("../pages/sodavand/TuborgSquash")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "Tuborg Squash",
    },
    meta: {
      title: "Tuborg Squash | Appelsinvand i den helt klassiske form online!",
      description:
        "Tuborg Squash var Danmarks første sodavand, og er stadig en sand klassiker blandt befolkningen. Elsker du også Tuborg Squash? – Så bestil dem hjem i dag!",
    },
    moms: false,
  },
  {
    path: "/sodavand/kinley",
    component: loadable(() => import("../pages/sodavand/Kinley")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "Kinley",
    },
    meta: {
      title: "Kinley Sodavand | Forfriskende & let Hindbær- og Citronvand!",
      description:
        "En Kinley Sodavand er altid god at have stående på køl, når man får lyst til en kold Kinley Hindbær- eller Citronvand! – Du kan bestille dine hos os i dag!",
    },
    moms: false,
  },
  {
    path: "/sodavand/pepsi-cola",
    component: loadable(() => import("../pages/sodavand/Pepsi")),
    breadcrumbs: {
      base: [HomeBC, SodavandBC],
      title: "Pepsi",
    },
    meta: {
      title: "Pepsi Cola | Find både den Klassiske og Max versionen online!",
      description:
        "Pepsi Cola er en sand favorit blandt mange. Vi leverer både til din hjemmet eller arbejdet - Køb Pepsi Cola produkter her - Leveret sodavand i over 30 år.",
    },
    moms: false,
  },
  /* DANSKVAND */
  {
    path: "/danskvand",
    component: loadable(() => import("../pages/sodavand/Danskvand")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Danskvand",
    },
    meta: {
      title: "Danskvand | Vand med brus | Fås med & uden Citrus | Køb her",
      description:
        "Danskvand [vand med brus] til en god pris online | Vi forhandler • Egekilde danskvand • Ramlösa • Frem enten med el. uden citrus → Se og Bestil dem her!",
    },
    moms: false,
  },
  /* KILDEVAND */
  {
    path: "/kildevand",
    component: loadable(() => import("../pages/kildevand/Kildevand")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Kildevand",
    },
    meta: {
      title: "Kildevand | Køb Billigt kildevand på flaske - Stort udvalg!",
      description:
        "Billigt Kildevand fra kendte mærker som • San Pellegrino • Aqua D&apos;or • Denice | Opnå mængderabat ved store bestillinger ✓Dag-dag levering i Kbh og omegn.",
    },
    moms: false,
  },
  {
    path: "/kildevand/aquador",
    component: loadable(() => import("../pages/kildevand/Aquador")),
    breadcrumbs: {
      base: [HomeBC, KildevandBC],
      title: "Aquador Mineralvand",
    },
    meta: {
      title: "Aquador Mineralvand | Køb dit mineralvand med og uden brus!",
      description:
        "Aquador Mineralvand er en klassiker af høj kvalitet, og kan fås både med eller uden brus – i udvalget kan du desuden også købe Aqua d'or vand med smag!",
    },
    moms: false,
  },
  {
    path: "/kildevand/denice-vand",
    component: loadable(() => import("../pages/kildevand/DeniceKildevand")),
    breadcrumbs: {
      base: [HomeBC, KildevandBC],
      title: "Kildevand i paller",
    },
    meta: {
      title:
        "Denice Kildevand | Billig kildevand til den prisbevidste - Køb her",
      description:
        "Denice Vand på 50 cl flasker - Denice kildevand er en billig kildevand, men af høj kvalitet ✓Billig kildevand  ✓Gratis levering - Køb dit mineralvand her.",
    },
    moms: false,
  },
  {
    path: "/kildevand/vand-paller",
    component: loadable(() => import("../pages/kildevand/KildevandPaller")),
    breadcrumbs: {
      base: [HomeBC, KildevandBC],
      title: "Kildevand i paller",
    },
    meta: {
      title: "Kildevand i hele paller",
      description:
        "Køb kildevand i paller, få den laveste pris. Flere forskellige slags kildevand - med & uden brus. Gratis dag til dag levering i hele landet. Bestil online!",
    },
    moms: false,
  },
  {
    path: "/kildevand/vand-med-logo",
    component: loadable(() => import("../pages/VandMedLogo")),
    breadcrumbs: {
      base: [HomeBC, KildevandBC],
      title: "Vand med logo",
    },
    search: {
      triggers: [
        "logo",
        "vand med logo",
        "logovand",
        "firmavand",
        "privat",
        "private label",
      ],
      image: "logovand.50cl-still.png",
    },
    meta: {
      title: "Vand Med Logo | Design og køb Logovand online hos Skafte.dk",
      description:
        "Køb Vand med Logo | Design jeres eget logovand eller få et uforpligtende designforslag. ✓Låg i flere farver ✓Med el. u. brus ✓Gratis levering ved 5 kasser.",
    },
    moms: false,
  },
  {
    path: "/kildevand/san-pellegrino-vand",
    component: loadable(() => import("../pages/kildevand/SanPellegrinoVand")),
    breadcrumbs: {
      base: [HomeBC, KildevandBC],
      title: "San Pellegrino vand",
    },
    meta: {
      title:
        "San Pellegrino og Acqua Panna - Naturligt mineralvand fra Italien",
      description:
        "San Pellegrino er ekslusivt italiensk kildevand, som fås med og uden brus i flere størrelser.",
    },
    moms: false,
  },
  {
    path: "/kildevand/vitamin-well",
    component: loadable(() => import("../pages/kildevand/VitaminWell")),
    breadcrumbs: {
      base: [HomeBC, KildevandBC],
      title: "Vitamin Well",
    },
    meta: {
      title: "Vitamin Well | Køb online i Danmark til en god pris – Skafte.dk",
      description:
        "Køb vitamindrikken - Vitamin Well | Indeholder få kalorier i forhold til sodavand og energidrinks | Flere varianter •  Awake • Care • Defence | Køb online.",
    },
    moms: false,
  },
  /* COCIO */
  {
    path: "/cocio",
    component: loadable(() => import("../pages/Cocio")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Cocio kakaomælk",
    },
    meta: {
      title: "Cocio - Få den kendte kakaomælk leveret til døren fra Skafte",
      description:
        "Cocio er en all-time favorit inden for chokolademælk - Vi forhandler både flaske (24cl & 40cl) og dåse (25cl) ✓Dag-til-dag levering ✓Bestil Cocio kakao nu.",
    },
    moms: false,
  },
  /* RED BULL */
  {
    path: "/red-bull",
    component: loadable(() => import("../pages/RedBull")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Red Bull",
    },
    meta: {
      title: "Køb Red Bull - Få den klassiske energidrik til god pris her",
      description:
        "Køb begge varianter af Red Bull energidrik online her! Både den originale og sukkerfri version på dåse hos os. Levering til både ✓private og ✓virksomheder.",
    },
    moms: false,
  },
  /* LÆSKEDRIKKE */
  {
    path: "/antons-saft",
    component: loadable(() => import("../pages/Antons")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Antons Økologisk Saft",
    },
    search: {
      triggers: ["antons", "saft"],
      image: "produkter-oelvand.antons-oeko-aeblemost-flaske.png",
    },
    meta: {
      title: "Antons - Salg og levering af økologisk saft fra Antons",
      description:
        "Få den lækre Antons økologiske saft leveret direkte til døren i hele Storkøbenhavn og omegn til en fantastisk pris! Bestil nu for en nem og velsmagende oplevelse.",
    },
    moms: false,
  },
  {
    path: "/soebogaard",
    component: loadable(() => import("../pages/Soebogaard")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Søbogaard Økologisk Saft",
    },
    search: {
      triggers: [
        "søbo",
        "søbogaard",
        "soebogaard",
        "saft",
        "sobogaard",
        "søbogård",
        "soebogård",
        "sobogård",
        "Søborggård",
        "soeborggård",
      ],
      image: "soebogaard.soebogaard-hyldeblomst-kasse.jpg",
    },
    meta: {
      title: "Søbogaard - Salg og levering af økologisk saft fra Søbogaard",
      description:
        "Økologisk saft fra Søbogaard leveres i hele Storkøbenhavn og omegn. Bestil den populære Søbogaard saft leveret lige til døren. Nemt og billigt - køb nu!",
    },
    moms: false,
  },
  {
    path: "/ice-tea",
    component: loadable(() => import("../pages/IceTea")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Ice Tea",
    },
    meta: {
      title: "Køb forfriskende Ice Tea - Flere smagsvarianter hos Skafte.dk",
      description:
        "Ice Tea er forfriskende afkølet te med smag. Vi fører tre af de bedste varianter på markedet, der både slukker tørsten og forfrisker - bestil online i dag!",
    },
    moms: false,
  },
  {
    path: "/juice",
    component: loadable(() => import("../pages/Juice")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Juice",
    },
    meta: {
      title: "Køb Juice fra Rynkeby og Solita",
      description: "Vælg mellem Rynkeby Æblemost og Solita Juice",
    },
    moms: false,
  },

  /* ANDET */
  {
    path: "/search/:searchTerm",
    noSearchBarResults: true,
    noSitemap: true,
    component: loadable(() => import("../components/Search/ResultsViewPage")),
    dontClearSearch: true,
    breadcrumbs: {
      base: [HomeBC],
      title: "Søg",
    },
    meta: {
      title: "",
      description: "",
    },
  },
  {
    path: "/search",
    noSearchBarResults: true,
    noSitemap: true,
    component: loadable(() => import("../components/Search/ResultsViewPage")),
    dontClearSearch: true,
    breadcrumbs: {
      base: [HomeBC],
      title: "Søg",
    },
    meta: {
      title: "",
      description: "",
    },
  },
  {
    path: "/bandit",
    component: loadable(() => import("../pages/Bandit")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Bandit lastbil",
    },
    meta: {
      title:
        "Bandit lastbil - Elektrisk ultra lav lastbil fra Peter Skafte Aps i Brøndby",
      description:
        "Godt for arbejdsmiljøet, niveaufri adgang til ladet i øl lastbil",
    },
  },
  {
    path: "/fadoel/billigt-fadoel",
    component: loadable(() => import("../pages/BilligtFadoel")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Billig Fadøl",
    },
    search: {
      triggers: ["billig fadøl", "billigt fadøl"],
      image: "produkter-fadoel.royal-pilsner.png",
    },
    meta: {
      title: "Billig fadøl - Spar penge og køb god tjekkisk & dansk fadøl",
      description:
        "Billig fadøl der smager fantastisk - tilfredshedsgaranti. Hent selv eller lad os levere både øl og fadølsanlæg. Ring eller bestil online.",
    },
  },
  {
    path: "/isterninger",
    component: loadable(() => import("../pages/Isterninger")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Isterninger",
    },
    search: {
      triggers: ["isterninger", "is", "tøris", "knust is"],
      image: "produkter-fadoel.isterninger.png",
      title: "Isterninger & is til fest",
      description: "Køb dine isterninger hos Skafte Øl & Vand",
    },
    meta: {
      title: "25 kg isterninger i termoboks - perfekt til festen",
      description:
        "Få 25 kg isterninger leveret direkte til døren i en smart termoboks. Ingen fest, uden kolde drinks - slip for besværet og køb isterninger online.",
    },
  },
  {
    path: "/cookies",
    component: loadable(() => import("../pages/Cookies")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Cookies",
    },
    meta: {
      title: "Cookiepolitik for hjemmesiden www.skafte.dk",
      description:
        "En cookie er en datafil, der bliver gemt på din computer. Filen benyttes til at holde styr på, hvad der sker under dit besøg.",
    },
  },
  {
    path: "/forbrug-af-fadoel",
    component: loadable(() => import("../pages/ForbrugAfFadoel")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Det drak andre kunder",
    },
    meta: {
      title: "Forbrug af fadøl - Hvor meget fadøl skal jeg købe?",
      description:
        "Se firmaer og privates forbrug af fadøl. Hvor mange fustager blev der drukket? Hvor mange fadøl drak hver gæst?",
    },
  },
  {
    path: "/holdbarhed-paa-fadoel",
    component: loadable(() =>
      import("../pages/holdbarhed/HoldbarhedPaaFadoel")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Holdbarhed på fadøl",
    },
    search: {
      triggers: ["holdbarhed"],
      image: "fadoel.fustage-uden-plumbering.png",
      title: "Holdbarhed på fadøl",
      description: "Hvor lang er holdbarheden på fustager før / efter åbning?",
    },
    meta: {
      title:
        "Holdbarhed på fadøl - Hvad er holdbarheden på åbnede og uåbnede fustager?",
      description:
        "Hvor længe kan vi bruge en åbnet fustage og hvor lang tid holder en uåbnet fustage?",
    },
  },
  {
    path: "/holdbarhed-paa-flasker-daaser",
    component: loadable(() =>
      import("../pages/holdbarhed/HoldbarhedPaaFlaskerDaaser")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Holdbarhed på flasker og dåser",
    },
    search: {
      triggers: ["holdbarhed"],
      image: "produkter-oelvand.5350-kasse.png",
      title: "Holdbarhed på flasker og dåser",
      description:
        "Hvor lang er holdbarheden på plast- og glasflasker, samt dåser?",
    },
    meta: {
      title: "Holdbarhed på flasker og dåser",
      description:
        "Hvor lang er holdbarheden på plast- og glasflasker, samt dåser?",
    },
  },
  {
    path: "/fustager-forklaring-vejledning",
    component: loadable(() => import("../pages/FustagerForklaringVejledning")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Teknisk forklaring om fustager",
    },
    search: {
      triggers: ["fustager"],
      image: "produkter-fadoel.carlsberg-fustage.png",
    },
    meta: {
      title: "Fustager - Sådan virker en fustage med fadøl",
      description:
        "Der er flere fordele ved fustager. Fustagen er den beholder, hvori fadøllet opbevares. Fustager påtappes de samme former for øl som almindelige flasker.",
    },
  },
  {
    path: "/hjaelpemidler",
    component: loadable(() => import("../pages/Hjaelpemidler")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Hjælpemidler til kasser",
    },
    meta: {
      title: "Hjælpemidler, flaskevogn til kasser & sækkevogn",
      description:
        "Levering af øl & sodavand samt fadøl overalt i storkøbenhavn. Slip for besværet og lad os køre det lige til døren og tage de tomme kasser med.",
    },
  },
  {
    path: "/link-til-lokaler-mad-og-udlejning",
    component: loadable(() => import("../pages/LinkTilLokalerMadOgUdlejning")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Links til lokaler, underholdning og mad m.m.",
    },
    meta: {
      title: "Link til selskabslokaler, serviceudlejning og mad ud af huset",
      description:
        "Skal du holde en stor fest, har du måske brug for mere plads og mad. Her finder du velegnede selskabslokaler og mad ud af huset.",
    },
  },
  {
    path: "/oekologiprocent",
    component: loadable(() => import("../pages/Oekologiprocent")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Økologiprocent",
    },
    meta: {
      title: "Økologiprocent i virksomheder og institutioner",
      description:
        "Økologiprocent er en udregning af, hvor stor en procentdel af ens råvareindkøb, der udgøres af økologiske råvarer. Lad os udregne den for dig.",
    },
    moms: false,
  },
  {
    path: "/pant-og-returregler",
    component: loadable(() => import("../pages/PantOgReturregler")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Pant- og returregler",
    },
    search: {
      triggers: [
        "pant",
        "returregler",
        "retur",
        "regler",
        "returpant",
        "returkasser",
        "returflasker",
      ],
      image: "retursystem.retursystem-skafte.jpg",
      subpageTriggers: [["pant fustager", "#pant-fustager"]],
      subpageData: {
        "#pant-fustager": {
          title: "Pant på ølfustage",
          image: "style.returpant.png",
          description:
            "Ligesom på øl og sodavandsflasker, er der pant på metaltønden som fadøl leveres i",
        },
      },
    },
    meta: {
      title: "Pant og returregler for flasker og dåser til drikkevarer",
      description:
        "Dansk Retursystem afhenter og udbetaler helt gratis pant for tomme engangs flasker og dåser",
    },
  },
  {
    path: "/betaling",
    component: loadable(() => import("../pages/Betaling")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Betaling",
    },
    search: {
      triggers: ["betaling", "faktura"],
      image: "style.kort-dankort.png",
    },
    meta: {
      title: "Betal hurtig og nemt online med betalingskort eller mobilepay",
      description:
        "Betal faktura eller andet beløb online. Vi modtager følgende betalingskort: Dankort, Visa, Mastercard, JCB, Maestro og American Express.",
    },
  },
  {
    path: "/betaling/success",
    component: loadable(() => import("../pages/BetalingSuccess")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Betaling gennemført",
    },
    meta: {
      title: "Betal med betalingskort gennemført!",
      description: "Betalingen med kort er sendt til ePay",
    },
  },
  /* Levering og Afhentning */
  {
    path: "/levering-afhentning",
    component: loadable(() =>
      import("../pages/levering-afhentning/LeveringAfhentning")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Levering & afhentning",
    },
    search: {
      triggers: ["afhentning", "levering", "udbringning"],
      image: "fadoel.afhent-fadoel1.jpg",
    },
    meta: {
      title: "Levering & afhentning",
      description: "Se hvad dine muligheder for levering eller afhentning er",
    },
  },
  {
    path: "/levering-afhentning/vilkaar-fadoel",
    component: loadable(() =>
      import("../pages/levering-afhentning/VilkaarFadoel")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Vilkår for fadøl & fadølsanlæg",
    },
    search: {
      triggers: ["vilkår", "handelsbetingelser", "betingelser"],
      image: "style.leveringsomraade.png",
    },
    meta: {
      title: "Leje af fadølsanlæg - Vilkår for fadøl og anlæg",
      description:
        "Lej et fadølsanlæg hos Skafte. Leveringsområde af fadølsanlæg: Overalt i Danmark. Fustager og anlæg leveres altid direkte på din adresse.",
    },
  },
  {
    path: "/levering-afhentning/levering/fadoel",
    component: loadable(() =>
      import("../pages/levering-afhentning/LeveringFadoel")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Levering af fadøl",
    },
    meta: {
      title: "Oplysninger om levering af fadøl fra Skafte Øl & Vand",
      description:
        "For at få dine varer kan du vælge 3 typer af udlevering: Afhent selv, levering, samt levering og afhentning",
    },
  },
  {
    path: "/leverandoer-sodavand-kildevand-oel",
    component: loadable(() => import("../pages/LeverandoerVandSodavandOel")),
    breadcrumbs: {
      base: [HomeBC, FlaskerOgDaaserBC],
    },
    meta: {
      title: "Øl og vand - Skafte er leverandør af sodavand, kildevand & øl",
      description:
        "Levering og indbæring af øl og sodavand i Storkøbenhavn og omegn. Stort udvalg. Nem bestilling. Hurtig levering.",
    },
    moms: false,
  },
  {
    path: "/levering-afhentning/afhentning",
    component: loadable(() =>
      import("../pages/levering-afhentning/Afhentning")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Afhent fadøl",
    },
    meta: {
      title:
        "Afhentning af fadølsanlæg & fadøl - Park Allé 352-A, 2605 Brøndby",
      description:
        "Sådan gør du, når du gerne selv vil hente fadøl og fadølsanlæg fra vores lager i Brøndby. Se hvordan du sparer penge på selv at hente.",
    },
  },
  /* Om os */
  {
    path: "/om-peter-skafte-aps",
    component: loadable(() => import("../pages/OmPeterSkafteApS")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Om Peter Skafte ApS",
    },
    search: {
      triggers: ["personale"],
      image: "kontakt.peter-skafte.jpg",
    },
    meta: {
      title: "Om Peter Skafte ApS - Park Allé 352A - 2605 Brøndby - 43270000",
      description: "Om firmaet | Kontakt os på telefon eller e-mail.",
    },
  },
  {
    path: "/historie",
    component: loadable(() => import("../pages/Historie")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Historie",
    },
    meta: {
      title: "Historien om Skafte.dk - Om ølhandler Peter Skafte",
      description:
        "Læs mere om vores historie her. Se hvordan vi blev en stor og stabil leverandør af øl og sodavand i København",
    },
  },
  /* Produkter Fadøl: /produkter-fadoel */
  {
    path: "/fadoel/dhl-fadoel",
    component: loadable(() => import("../pages/fadoel/DhlFadoel")),
    breadcrumbs: {
      base: [HomeBC, FadoelBC],
      title: "DHL Stafet fadøl",
    },
    search: {
      triggers: ["dhl", "dhl stafet", "stafet", "dhl fadøl"],
      image: "diverse.dhl-logo.png",
    },
    meta: {
      title: "Fadølsanlæg til DHL Stafet i Fælledparken leveres & opsættes",
      description:
        "Har I lejet et telt til DHL Stafet og skal bruge fadøl & anlæg efter løbet? Vi har god erfaring med levering, opsætning & afhentning til DHL",
    },
  },
  {
    path: "/fadoel/oeltyper",
    component: loadable(() => import("../pages/produkter-fadoel/Oeltyper")),
    noSitemap: true,
    breadcrumbs: {
      base: [HomeBC],
      title: "Øltyper",
    },
    search: {
      triggers: ["øltyper"],
      image: "produkter-fadoel.groen-tuborg.png",
    },
    meta: {
      title: "Øltyper - en gennemgang af de fadølstyper vi har i sortimentet",
      description:
        "Information om øltyperne i vores fadølssortiment strækker sig over de undergærede Pilsner, Classic og Lagerøl til de overgærede Hvedeøl, Ales og Stout",
    },
  },
  /* Videoer */
  {
    path: "/videoer",
    component: loadable(() => import("../pages/videoer")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
    },
    search: {
      triggers: ["video", "videoer", "vejledning"],
      image: "video.opsaetning-af-fadoelsanlaeg.png",
    },
    meta: {
      title: "Fadølsvideoer om fadøl og fadølsanlæg",
      description:
        "Instruktioner til fadøl og fadølsanlæg - opsætning, skift af fustager, kulsyre, fadkoblinger og meget mere.",
    },
  },
  {
    path: "/videoer/saadan-tilsluttes-slange-mellem-kulsyre-og-sodastream",
    component: loadable(() =>
      import("../pages/videoer/SaadanTilsluttesSlangeMellemKulsyreOgSodastream")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Sådan tilsluttes slange mellem kulsyre og sodastream",
    },
    meta: {
      title: "Video: Sådan tilsluttes slange mellem kulsyre og sodastream",
      description:
        "Når du køber slange med tilslutning til kulsyre og sodastream, får du denne pose.",
    },
  },
  {
    path: "/videoer/saadan-tilsluttes-grohe-blue-danskvandshane",
    component: loadable(() =>
      import("../pages/videoer/SaadanTilsluttesGroheBlue")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Sådan tilsluttes Grohe Blue danskvandshane",
    },
    meta: {
      title: "Video: Sådan tilsluttes Grohe Blue danskvandshane",
      description:
        "Se hvordan du tilslutter Grohe Blue danskvandshane til kulsyre",
    },
  },
  {
    path: "/videoer/aftagning-af-fastsiddende-fadkobling",
    component: loadable(() =>
      import("../pages/videoer/AftagningAfFastsiddendeFadkobling")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Aftagning af fastsiddende fadkobling",
    },
    meta: {
      title: "Video: Sådan gør du når du ikke kan få fadkoblingen af fustagen",
      description:
        "Instruktion til hvordan du får en fadkobling som sidder fast, af en fustage",
    },
  },
  {
    path: "/videoer/fyld-vand-paa-fadoelsanlaeg",
    component: loadable(() =>
      import("../pages/videoer/FyldVandPaaFadoelsAnlaeg")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Fyld vand på fadølsanlæg",
    },
    meta: {
      title: "Video: Fyld vand på fadølsanlæg",
      description: "Det er meget vigtigt, at der nok vand på anlægget.",
    },
  },
  {
    path: "/videoer/john-guest",
    component: loadable(() => import("../pages/videoer/JohnGuest")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Tilslutning af slange til John Guest",
    },
    meta: {
      title: "Video: Tilslutning af slange til John Guest",
      description:
        "Man behøver ikke noget værktøj for at tilslutte en slange til en John Guest.",
    },
  },
  {
    path: "/videoer/opsaetning-stilladsbar",
    component: loadable(() => import("../pages/videoer/OpsaetningStilladsbar")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Opsætning af stilladsbar",
    },
    meta: {
      title: "Video: Opsætning af stilladsbar",
      description:
        "Opsætning af stilladsbar - med plads til 3 fadølshaner og fadølsanlæg - sådan opsættes baren.",
    },
  },
  {
    path: "/videoer/tropekoelere-skift-lynkobling",
    component: loadable(() =>
      import("../pages/videoer/TropekoelereSkiftLynkobling")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Tropekølere og tilslutning af lynkoblinger",
    },
    meta: {
      title: "Video: Tropekølere og tilslutning af lynkoblinger",
      description:
        "Se her hvordan du tropekølere virker og hvordan man tilslutter lynkoblinger på både tropekølere og almindelige fadølsanlæg",
    },
  },
  {
    path: "/videoer/skift-fadoelskobling",
    component: loadable(() => import("../pages/videoer/SkiftFadoelskobling")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Sådan skifter du en fadølskobling",
    },
    meta: {
      title: "Video: Sådan skifter du en fadølskobling. Fra S- til A-kobling",
      description:
        "Når du køber fadøl kan der være forskellige koblinger på fustagerne. Se her hvordan du skifter dem.",
    },
  },
  {
    path: "/videoer/kulsyre-tjek",
    component: loadable(() => import("../pages/videoer/KulsyreTjek")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Tjek om der er kulsyre på kulsyreflasken",
    },
    meta: {
      title: "Video: Tjek om der er kulsyre på kulsyreflasken",
      description:
        "Sådan tjekker du nemt og hurtigt om der er kulsyre på kulsyreflasken. Se hvordan du åbner og lukker for kulsyreflasken.",
    },
  },
  {
    path: "/videoer/forskel-fadoelsglas",
    component: loadable(() => import("../pages/videoer/ForskelFadoelsGlas")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Forskel på fadølsglas",
    },
    meta: {
      title: "Video: Kvalitetsglas fra Skafte > plastikglas fra supermarkedet",
      description:
        "Der er meget stor forskel på skummet fra fadøl når man bruger kvalitetsglas fra www.skafte.dk mod almindelige plastikglas fra supermarkedet.",
    },
  },
  {
    path: "/videoer/plastikslange-fadkobling",
    component: loadable(() =>
      import("../pages/videoer/PlastikslangeFadkobling")),
    breadcrumbs: {
      base: [HomeBC, VideoerBC],
      title: "Sådan skifter du en fadølskobling",
    },
    meta: {
      title: "Video: Sådan sætter du plastikslange på en fadkobling",
      description:
        "Et meget nemt og enkelt trick til når du skal skifte slange ved brug af klemmebånd og kogende vand.",
    },
  },
  /* FAQ */
  {
    path: "/faq/faq",
    component: loadable(() => import("../pages/faq/Faq")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
    },
    meta: {
      title: "FAQ - Ofte stillede spørgsmål om fadøl, fadølsanlæg og sodavand",
      description:
        "Ofte stillede spørgsmål, vejledning, kritik om fadølsanlæg og fadøl, sodavand, kildevand, levering, kulsyre og meget mere",
    },
  },
  {
    path: "/faq/tager-i-ubrugte-fustager-retur",
    component: loadable(() => import("../pages/faq/UbrugteFustager")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Tager I ubrugte fustager retur?",
    },
    search: {
      triggers: ["ubrugte", "fustager", "ubrudte"],
      image: "produkter-fadoel.carlsberg-fustage.png",
    },
    meta: {
      title: "Tager I ubrugte fustager retur?",
      description:
        "Ja, vi tager ubrudte fustager retur mod et gebyr.",
    },
  },
  {
    path: "/faq/blandingsgas",
    component: loadable(() => import("../pages/faq/Blandingsgas")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Sælger I blandingsgas?",
    },
    search: {
      triggers: ["blandingsgas", "mixgas", "nitrogen"],
      image: "kulsyre.blandingsgas-8liter.png",
    },
    meta: {
      title: "Sælger I blandingsgas?",
      description:
        "Ja, vi sælger blandingsgas. Kontakt os på telefon 43 27 00 00 eller skriv til os på peter@skafte.dk.",
    },
  },
  {
    path: "/faq/fortrydelsesret",
    component: loadable(() => import("../pages/faq/Fortrydelsesret")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Kan jeg fortryde et online køb hos Peter Skafte?",
    },
    meta: {
      title: "Kan jeg fortryde et online køb hos Peter Skafte?",
      description:
        "Læs her hvilke regler der er gældende for fortrydelsesret når du køber fadøl og drikkevarer hos Skafte Øl & Vand.",
    },
  },
  {
    path: "/faq/salg-af-soebogaard-til-privatforbrug",
    component: loadable(() =>
      import("../pages/faq/SalgAfSoebogaardTilPrivatforbrug")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Salg af Søbogaard til privatforbrug?",
    },
    meta: {
      title: "Salg af Søbogaard til privatforbrug?",
      description:
        "Hej Skafte.dk. Er det muligt at bestille Søbogaard saft til privatforbrug, så længe der bestilles minimum 5 kasser?",
    },
  },
  {
    path: "/faq/betingelser-for-samarbejde",
    component: loadable(() => import("../pages/faq/BetingelserForSamarbejde")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvad er betingelserne for en samarbejdsaftale?",
    },
    meta: {
      title: "Hvad er betingelserne for en samarbejdsaftale?",
      description:
        "Jeg vil høre om I kan oplyse mig om betingelserne for en samarbejdsaftale? Er der er minimumskrav for aftagelse, hvor ofte kan der ske levering osv.",
    },
  },
  {
    path: "/faq/hvilket-aar-blev-pant-c-3-kr",
    component: loadable(() => import("../pages/faq/HvilketAarBlevPantC3Kr")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvilket år blev pant c flasken 3 kr?",
    },
    meta: {
      title: "Hvilket år blev pant c flasken 3 kr?",
      description:
        "Ved I hvilket år pant c flasken blev 3 kr? Har den altid været det, eller er den blevet sat op? Se svaret her.",
    },
  },
  {
    path: "/faq/kan-kulsyre-flasken-taale-at-ligge-ned",
    component: loadable(() =>
      import("../pages/faq/KanKulsyreFlaskenTaaleAtLiggeNed")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Kan kulsyre flasken tåle at ligge ned?",
    },
    meta: {
      title: "Kan kulsyre flasken tåle at ligge ned?",
      description:
        "Under transport er det uden betydning hvordan kulsyreflasken vender. Det er muligt at flasken ligger ned under brug - læs her hvad du skal være opmærksom på",
    },
  },
  {
    path: "/faq/paafyldning-af-co2-kulsyre",
    component: loadable(() => import("../pages/faq/PaafyldningAfCo2Kulsyre")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Påfyldning af Co2 på kulsyreflaske?",
    },
    search: {
      triggers: ["påfyldning"],
    },
    meta: {
      title: "Påfyldning af Co2 på kulsyreflaske?",
      description:
        "Læs her hvad vi gør i forhold til påfyldning af kulsyreflasker, ombytning og returnering. Det er også muligt at købe kulsyreflasker.",
    },
  },
  {
    path: "/faq/kulsyre-til-svejsning",
    component: loadable(() => import("../pages/faq/KulsyreTilSvejsning")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Kan man bruge jeres kulsyre til svejsning?",
    },
    meta: {
      title: "Kan man bruge jeres kulsyre til svejsning?",
      description:
        "FAQ - Du kan sagtens bruge fadøls co2 til at svejse med. 'Jeg har sådan to co2 flasker til og stå. Flasken er grå og er en 6kg.'",
    },
  },
  {
    path: "/faq/kulsyre-til-postmix",
    component: loadable(() => import("../pages/faq/KulsyreTilPostmix")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvor meget kulsyre til postmix?",
    },
    meta: {
      title: "Hvor meget kulsyre til postmix?",
      description:
        "Hvor meget kulsyre skal der bruges til postmix? - FAQ - Se svaret her på skafte.dk - Eksperter i fadøl, fadølsanlæg, kulsyre, sodavand og andre drikkevarer!",
    },
  },
  {
    path: "/faq/kulsyre-flaskens-totale-vaegt",
    component: loadable(() =>
      import("../pages/faq/KulsyreFlaskensTotaleVaegt")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title:
        "6 kg kulsyreflasker, er det flaskens totale vægt inkl. indholdet?",
    },
    meta: {
      title:
        "6 kg kulsyreflasker, er det flaskens totale vægt inkl. indholdet?",
      description:
        "De 6 kg er vægten af CO2'en i den fyldte flaske. Rum-indholdet i flasken er 8 liter. Den tomme flaske vejer ca. 9kg",
    },
  },
  {
    path: "/faq/hvor-lang-tid-foer-skal-jeg-bestille",
    component: loadable(() =>
      import("../pages/faq/HvorLangTidFoerSkalJegBestille")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvor lang tid før brug skal jeg bestille?",
    },
    meta: {
      title: "Hvor lang tid før brug skal jeg bestille?",
      description:
        "Skal du bruge fadøl og fadølsanlæg til en fest? Er du i tvivl om hvor lang tid før du skal bestille? Se vores svar her.",
    },
  },
  {
    path: "/faq/hvornaar-kan-fadoelsanlaeg-tilsluttes",
    component: loadable(() =>
      import("../pages/faq/HvornaarKanFadoelsanlaegTilsluttes")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title:
        "Kan fadøl og fadølsanlæg tilsluttes og opsættes 1 - 2 dage før festen?",
    },
    meta: {
      title:
        "Kan fadøl og fadølsanlæg tilsluttes og opsættes 1 - 2 dage før festen?",
      description:
        "Kan fadøl og fadølsanlæg tilsluttes og opsættes 1 - 2 dage før festen, uden at ølsmagen forringes (bliver fladt eller dårligt)?",
    },
  },
  {
    path: "/faq/langtidsudlaan-jeg-skal-holde-2-fester",
    component: loadable(() =>
      import("../pages/faq/LangtidsudlaanJegSkalHolde2Fester")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Langtidsudlån - Jeg skal holde 2 fester. Hvad er konditionerne?",
    },
    meta: {
      title: "Langtidsudlån - Jeg skal holde 2 fester. Hvad er konditionerne?",
      description:
        "Nu er det blevet til en privat fest (20/10) og 2 uger senere (3/11) en vejfest med samme tema. Hvad er konditionerne hvis jeg skal beholde anlæg og fustager",
    },
  },
  {
    path: "/faq/rensning-anlaeg-sjaeldent-brugt",
    component: loadable(() =>
      import("../pages/faq/RensningAnlaegSjaeldentBrugt")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Rensning af anlæg, der er sjældent brugt?",
    },
    meta: {
      title: "Rensning af anlæg, der er sjældent brugt?",
      description:
        "Skal der renses både før og efter brug, når anlægget f.eks. bliver brugt hvert halve år? Og hvordan er det med rensevæske og kulsyre?",
    },
  },
  {
    path: "/faq/rensedunk-til-blanding-af-drinks",
    component: loadable(() =>
      import("../pages/faq/RensedunkTilBlandingAfDrinks")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Rensedunk til blanding af drinks?",
    },
    meta: {
      title: "Rensedunk til blanding af drinks?",
      description:
        "Vi skal bruge en rensedunk til at putte en blanding af saftevand og vodka i og slutte den til et fadølsanlæg. Kan man dette?",
    },
  },
  {
    path: "/faq/alkoholprocent",
    component: loadable(() => import("../pages/faq/Alkoholprocent")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvad er alkoholprocenten i fadøl?",
    },
    meta: {
      title: "Hvad er alkoholprocenten i fadøl?",
      description:
        "Fadøl har normalt samme alkohl procent som den tilsvarende flaske eller dåseøl. Læs her hvad forskellen er.",
    },
  },
  {
    path: "/faq/fadoel-skummer",
    component: loadable(() => import("../pages/faq/FadoelSkummer")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvad gør man, når fadøllet skummer for meget?",
    },
    search: {
      triggers: ["kun skum", "skummer for meget"],
    },
    meta: {
      title: "Hvad gør man, når fadøllet skummer for meget?",
      description:
        "Når fadøl skummer er det næsten altid fordi det ikke er koldt nok. Løsningen kan være flere forskellige ting. Se mere her.",
    },
  },
  {
    path: "/faq/fadoel-temperatur",
    component: loadable(() => import("../pages/faq/FadoelTemperatur")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvad er den perfekte temperatur for fadøl?",
    },
    meta: {
      title: "Hvad er den perfekte temperatur for fadøl?",
      description:
        "Se hvilken temperatur der er optimal. De fleste fadølsanlæg giver helt automatisk den rette temperatur og skal ikke justeres.",
    },
  },
  {
    path: "/faq/fustage-holdbarhed",
    component: loadable(() => import("../pages/faq/FustageHoldbarhed")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvordan er holdbarheden på en fadøls fustage?",
    },
    meta: {
      title: "Hvordan er holdbarheden på en fadøls fustage?",
      description:
        "Holbarheden svinger fra den ene producent til den anden. Men regn med ca. 6 månder for uåbnede fustager.",
    },
  },
  {
    path: "/faq/gammelt-fadoelsanlaeg-mangler-gas",
    component: loadable(() =>
      import("../pages/faq/GammeltFadoelsanlaegManglerGas")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Jeg har købt et gammelt fadølsanlæg - mangler gas?",
    },
    meta: {
      title: "Jeg har købt et gammelt fadølsanlæg - mangler gas?",
      description:
        "Jeg har skaffet mig et ældre fadølsanlæg med en enkelt tappehane & en rensedunk og noget rensevæske. Jeg mangler gas og øl hvis jeg ikke tager helt fejl",
    },
  },
  {
    path: "/faq/kalorier-fadoel",
    component: loadable(() => import("../pages/faq/KalorierFadoel")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Hvor mange kalorier er der egentlig i fadøl?",
    },
    meta: {
      title: "Hvor mange kalorier er der egentlig i fadøl?",
      description:
        "Læs her hvor mange kalorier der er i forskellige typer fadøl, som Grønt Tuborg, Tuborg Classic, og gennemsnittet på 3 forskeelige øltyper",
    },
  },
  {
    path: "/faq/er-hanerne-med-kompensator",
    component: loadable(() => import("../pages/faq/ErHanerneMedKompensator")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Er fadølshanerne med kompensator?",
    },
    meta: {
      title: "Er fadølshanerne med kompensator?",
      description:
        "Muligheden for at skrue ned for udløbshastigheden behøves ikke på vandkølede anlæg, og fraværet giver kun fordele.",
    },
  },
  {
    path: "/faq/fadoel-i-aarhus-og-omegn",
    component: loadable(() => import("../pages/faq/FadoeliAarhusOgOmegn")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Leverer I fadøl og fadølsanlæg i Århus og omegn?",
    },
    meta: {
      title: "Leverer I fadøl og fadølsanlæg i Århus og omegn?",
      description:
        "Læs her hvilken kollega vi henviser til hvis du skal bruge fadøl og fadølsanlæg i Århus og omegn",
    },
  },
  {
    path: "/faq/fadoel-i-sydoest-jylland",
    component: loadable(() => import("../pages/faq/FadoeliSydoestJylland")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Leverer I fadøl og fadølsanlæg i Sydøst Jylland?",
    },
    meta: {
      title: "Leverer I fadøl og fadølsanlæg i Sydøst Jylland?",
      description:
        "Læs her hvilken kollega vi henviser til hvis du skal bruge fadøl og fadølsanlæg i sydøst jylland (Kolling og omegn)",
    },
  },
  {
    path: "/faq/fadoelsanlaeg-til-keykeg",
    component: loadable(() => import("../pages/faq/FadoelsanlaegTilKeykeg")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "Har I fadølsanlæg til KeyKeg?",
    },
    search: {
      triggers: ["specialøl"],
      image: "produkter-oelvand.tiger-flaske.png",
      title: "Specialøl og andre øl på flaske",
    },
    meta: {
      title: "Har I fadølsanlæg til KeyKeg?",
      description:
        "Hej, vi har købt specialøl på en kompositfustage med KEG-kobling, og det er åbenbart ikke så nemt for udlejere af fadølsanlæg.",
    },
  },
  {
    path: "/faq/stil-et-spoergsmaal",
    component: loadable(() => import("../pages/faq/StilEtSpoergsmaal")),
    breadcrumbs: {
      base: [HomeBC, FaqBC],
      title: "FAQ - Stil et spørgsmål",
    },
    meta: {
      title: "FAQ. Stil et spørgsmål om fadøl, fadølsanlæg, sodavandslevering.",
      description:
        "Vi sætter stor pris på din hjælp. Send gerne spørgsmål, kritik ind til os. Spørgsmålene kan blive brugt på vores FAQ side (uden personlige oplysninger).",
    },
  },
  {
    path: "/kontakt-os",
    component: loadable(() => import("../pages/Kontakt")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Kontakt",
    },
    search: {
      triggers: ["åbningstider", "kontakt"],
      image: "search.kontakt.jpg",
      title: "Kontakt & åbningstider",
      description: "Kontaktoplysninger - Åbningstider - Kontaktformular",
    },
    meta: {
      title: "Kontakt Peter Skafte ApS",
      description: "På telefon, email eller vha. vores kontaktformular",
    },
  },
  {
    path: "/priser",
    component: loadable(() => import("../pages/Priser")),
    breadcrumbs: {
      base: [HomeBC, FlaskerOgDaaserBC],
      title: "Priser & Sortiment",
    },
    search: {
      triggers: ["priser"],
      image: "produkter-oelvand.coca-cola-50cl.png",
    },
    meta: {
      title: "Priser på sodavand, kildevand & øl leveret i hele Storkøbenhavn",
      description:
        "Gratis levering ved kun 5 kasser. Dag til dag levering. Aquad d'or, Søbogaard, San Pellegrino, MySmoothie, Coca-Cola, Faxe Kondi, Cocio & Vitamin Well m.fl",
    },
    moms: false,
  },
  {
    path: "/tools/svg",
    component: loadable(() => import("../pages/tools/Svg")),
    noSitemap: true,
    breadcrumbs: {
      base: [HomeBC],
      title: "SVG Export",
    },
    meta: {
      title: "SVG Export",
      description: "",
    },
  },
  /*
  {
    path: '/job',
    component: loadable(() => import('../pages/Job')),
    breadcrumbs: {
      base: [HomeBC],
      title: 'Job',
    },
    meta: {
      title: 'Øl-chauffør søges til distribution af øl og vand i København',
      description: 'Fast fuldtids job tilbydes. Til distribution med lastbil søges service-minded chauffør med god fysik.',
    },
  },
  */
  /*
  {
    path: '/salg-reservedele-fadoelsanlaeg',
    component: loadable(() => import('../pages/SalgReservedeleFadoelsanlaeg')),
    breadcrumbs: {
      base: [HomeBC],
      title: 'Salg af reservedele',
    },
    meta: {
      title: 'Salg af reservedele til fadølsanlæg & salg af nye/brugte anlæg',
      description: 'Brugte fadølsanlæg til salg, køb også et nyt til privat brug og få glæde af frisk skummende fadøl, når du har lyst.',
    },
  },
  */
  {
    path: "/sitemap",
    component: loadable(() => import("../pages/Sitemap")),
    breadcrumbs: {
      base: [HomeBC],
      title: "Sitemap",
    },
    meta: {
      title: "Sitemap - Overblik over alle sider på Skafte.dk",
      description:
        "Oversigt over Skafte.dk - find vej til sodavand, kildevand, fadøl, søbogaard, cocio mm.",
    },
  },
];
export default [
  ...routes,
  ...itemRoutes,
  /*
   *
   *
   * Not Found
   *
   *
   */
  {
    path: "",
    component: loadable(() => import("../pages/PageNotFound")),
    catchAll: true,
  },
];

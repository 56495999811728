import React from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { Row, Col } from "../grid";
import { LinkButton, Paragraph } from "../typography";
import Modal from "../Modal";
import { acceptRoyalUnibrew } from "../../features/commerce/bucket/actions";
import { isOfferingRoyalUnibrewOffer } from "../../features/commerce/bucket/selectors";
import { Image } from "../Image";
import RoyalUnibrewOfferText from "../RoyalUnibrewOfferText";

const StyledModal = styled(Modal)`
  z-index: 99999999999;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.colors.colorWhite};
  border: 2px ${({ theme }) => theme.colors.colorGreyLightAlt} solid;
  outline: none;
  overflow-y: hidden;
  overflow-x: hidden;

  width: 550px;
  padding: 20px;

  @media (max-width: 991px) {
    width: 400px;
    padding: 10px;
  }

  @media (max-width: 767px) {
    width: 280px;
    padding: 10px;
  }

  @media (max-height: 572px) {
    top: 100px !important;
    height: calc(80% - 100px);
    margin-top: 0px;
    transform: translate(-50%, 0) !important;
    overflow-y: scroll;
  }
`;
const RoyalUnibrewOfferModal = ({ isOpen, onNo, onYes }) => (
  <StyledModal isOpen={isOpen} onRequestClose={onNo}>
    <Row>
      <Col xs={12} md={3} textCenter>
        <Image
          name="produkter-oelvand.royal-blanch-daase.png"
          maxHeight="200px"
        />
      </Col>
      <Col xs={12} md={9}>
        <RoyalUnibrewOfferText />
        <Paragraph bold fontSize="16px">
          Ønsker du gratis 1 ramme Royal Blanche dåser med til din bestilling?
        </Paragraph>
        <Row>
          <Col xs={6}>
            <LinkButton action onClick={onYes}>
              Ja tak
            </LinkButton>
          </Col>
          <Col xs={6}>
            <LinkButton action red onClick={onNo}>
              Nej tak
            </LinkButton>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledModal>
);

export default connect(
  (state) => ({
    isOpen: isOfferingRoyalUnibrewOffer(state),
  }),
  (dispatch) => ({
    onYes: () => dispatch(acceptRoyalUnibrew(true)),
    onNo: () => dispatch(acceptRoyalUnibrew(false)),
  })
)(RoyalUnibrewOfferModal);

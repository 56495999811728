import React, {
  useState,
  useEffect,
} from 'react';
import styled from 'styled-components/macro';
import Modal from 'react-modal';
import { Div } from './grid';
import { BsCaretDownFill, BsCaretRightFill } from './Icons';
import { Paragraph, Spacer } from './typography';
import {
  consentValue,
  chooseOptInOrOut,
  optScripts,
} from '../lib/analytics';
import LogoNoLink from '../layout/DefaultLayout/Header/components/Logo/LogoNoLink';
import { CookiesPolitikContent, CookiesPolitikSubTitle, CookiesPolitikTitle } from '../pages/CookiesPolitik';

//_clck	Persists the Clarity User ID and preferences, unique to that site is attributed to the same user ID.
//_clsk	Connects multiple page views by a user into a single Clarity session recording.
//CLID	Identifies the first-time Clarity saw this user on any site using Clarity.
//ANONCHK	Indicates whether MUID is transferred to ANID, a cookie used for advertising. Clarity doesn't use ANID and so this is always set to 0.
//MR	Indicates whether to refresh MUID.
//MUID	Identifies unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes.
//SM	Used in synchronizing the MUID across Microsoft domains.


//SL_C_*_SID Contains the project key, session ID, and visitor ID. Session and visitor IDs are unique identifiers assigned to new sessions and visitors.
//SL_L_*_SID Contains the project key, session ID, and visitor ID. Session and visitor IDs are unique identifiers assigned to new sessions and visitors. Data is stored locally.


//_uetsid A session identifier used by Microsoft Bing Ads to measure ad campaign success
//_uetvid A unique identifier used by Microsoft Bing Ads to measure ad campaign success

//_glc_au to store and track conversions.  Google Adsense


//test_cookie This cookie is set by DoubleClick (which is owned by Google) to determine if the website visitor's browser supports cookies.
// IDE This cookie is set by Doubleclick and carries out information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.


// FPLC This FPLC cookie is the cross-domain linker cookie hashed from the FPID cookie. It’s not HttpOnly, which means it can be read with JavaScript. It has a relatively short lifetime, just 20 hours.
// FPID This FPID cookie is the first-party ID cookie. It’s HttpOnly, which means it can’t be read with JavaScript. 1 year 1 month lifespan.


// SRM_B Identifies unique web browsers visiting Microsoft sites. These cookies are used for advertising, site analytics, and other operational purposes.
// MSPTC Not classified 1 year
const cookieContent = {
  nessesary: [
    { name: 'skafte_session', purpose: 'Understøtter websitets tekniske funktioner.', duration: '2 timer', owner: 'Skafte.dk', privacyPolicy: 'https://skafte.dk/cookies' },
    { name: 'XSRF-TOKEN', purpose: 'Sikkerhedscookie, så du ikke bliver udsat for XSRF angreb.', duration: '2 timer', owner: 'Skafte.dk', privacyPolicy: 'https://skafte.dk/cookies' },
  ],
  statistics: [
    { name: '_ga', purpose: 'Google Analytics cookie, der bruges til at skelne brugere fra hinanden.', duration: '1 år og 1 måned', owner: 'Google Analytics', privacyPolicy: 'https://policies.google.com/privacy?hl=da' },
    { name: '_ga_*', purpose: 'Google Analytics cookie, der bruges til at bevare informationer om den nuværende session', duration: '2 år', owner: 'Google Analytics', privacyPolicy: 'https://policies.google.com/privacy?hl=da' },
    { name: 'SL_C_*_SID', purpose: 'Smartlook cookie, der bruges til at bevare informationer om den nuværende session', duration: 'Session', owner: 'Smartlook', privacyPolicy: 'https://help.smartlook.com/docs/privacy-policy' },
    { name: 'SL_L_*_SID', purpose: 'Smartlook cookie, der bruges til at bevare informationer om den nuværende session', duration: 'Session', owner: 'Smartlook', privacyPolicy: 'https://help.smartlook.com/docs/privacy-policy' },
    { name: 'FPID', purpose: 'Google TagsManager server-side cookie, der bruges til at bevare informationer til at skelne brugere fra hinanden', duration: '1 år og 1 måned', owner: 'Skafte.dk', privacyPolicy: 'https://skafte.dk/cookies' },
    { name: 'FPLC', purpose: 'Google TagsManager server-side cookie, der bruges til at bevare informationer om den nuværende session', duration: '20 timer', owner: 'Skafte.dk', privacyPolicy: 'https://skafte.dk/cookies' },
  ],
  marketing: [
    { name: 'test_cookie', purpose: 'Denne cookie er sat af DoubleClick (som ejes af Google) for at afgøre, om hjemmesidebesøgerens browser understøtter cookies.', duration: '15 minutter', owner: 'Google DoubleClick', privacyPolicy: 'https://policies.google.com/privacy?hl=da' },
    { name: 'IDE', purpose: 'Brugt af Google DoubleClick til at registrere og rapportere hjemmesidebrugerens handlinger efter at have set eller klikket på en af annoncørens annoncer med det formål at måle effektiviteten af en annonce og for at præsentere målrettede annoncer for brugeren.', duration: '1 år', owner: 'Google DoubleClick', privacyPolicy: 'https://policies.google.com/privacy?hl=da' },
    { name: '_glc_au', purpose: 'Google Adsense cookie, der bruges til at bevare informationer om den nuværende session', duration: '3 måneder', owner: 'Google Adsense', privacyPolicy: 'https://policies.google.com/privacy?hl=da'},
    { name: '_clck', purpose: 'Opbevarer Clarity User ID og præferencer, så unikke besøg på tværs af hjemmesider kan tilskrives samme bruger ID.', duration: '1 år', owner: 'Microsoft Clarity', privacyPolicy: 'https://policies.google.com/privacy?hl=da' },
    { name: '_clsk', purpose: 'Forbinder flere sidevisninger af en bruger til en enkelt Clarity session recording.', duration: '1 år', owner: 'Microsoft Clarity', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: 'CLID', purpose: 'Identificerer første gang Clarity så denne bruger på en hvilken som helst hjemmeside, der bruger Clarity.', duration: '1 år', owner: 'Microsoft Clarity', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: 'ANONCHK', purpose: 'Cookie med ukendt funktionalitet, der bliver tilføjet af Bing ads', duration: '10 minutter', owner: 'Microsoft Clarity', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: 'MR', purpose: 'Indikerer, om MUID skal opdateres.', duration: '7 dage', owner: 'Microsoft Clarity', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: 'MUID', purpose: 'For at gemme og opbevarer besøg på tværs af hjemmesider', duration: '1 år', owner: 'Microsoft Clarity', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: 'SM', purpose: 'Bruges til at synkronisere MUID på tværs af Microsoft domæner.', duration: '1 år', owner: 'Microsoft Clarity', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: '_uetsid', purpose: 'Bruges af Microsoft Bing Ads til at måle succesen af annonceringskampagner.', duration: '1 dag', owner: 'Microsoft Bing Ads', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: '_uetvid', purpose: 'Bruges af Microsoft Bing Ads til at måle succesen af annonceringskampagner.', duration: '1 år og 2 måneder', owner: 'Microsoft Bing Ads', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: 'SRM_B' , purpose: 'Bruges af Microsoft Bing Ads', duration: '1 år', owner: 'Microsoft Bing Ads', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: 'MSPTC', purpose: 'Bruges af Microsoft Bing Ads', duration: '1 år', owner: 'Microsoft Bing Ads', privacyPolicy: 'https://privacy.microsoft.com/da-dk/privacystatement' },
    { name: '_fbp', purpose: 'Bruges af Facebook til at levere en række reklameprodukter såsom realtidsbudgivning fra tredjepartsannoncører.', duration: '3 måneder', owner: 'Facebook', privacyPolicy: 'https://www.facebook.com/policies/cookies/' },
  ],
};
const customStyles = {
  content: {
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    zIndex: 99999999999,
  },
};
const ModalNoProps = ({ ...props }) => (
  <Modal style={customStyles} {...props} />
);

const Link = styled.a`

`;

const StyledModal = styled(ModalNoProps)`
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  background: transparent;
  position: fixed;
  padding: 15px;
  overflow: hidden;
  outline: 0;
  border: 0px;
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  width: 600px;
`;

const Wrap = styled.div`
  background: #ffffff;
  border-radius: 5px;
  overflow: hidden;
`;

const CookieContent = styled.div`
  background: #f6f6f6;
  background: ${({ showingDetails }) => (showingDetails ? '#ffffff' : '#f6f6f6')};
  padding: 40px;
  padding-bottom: 1px;
  overflow-y: auto;
  max-height: calc(100dvh - 110px);

  @media (max-width: 635px) {
    padding: 20px;
    padding-bottom: 1px;
  }
`;

const Heading = styled.h3`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const CookieButtons = styled.div`
  background: #ffffff;
  padding: 40px;
  margin: 0 -40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  width: 100%;

  @media (max-width: 635px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    margin: 0 -20px;
  }
`;

const CookieButton = styled.div`
  background: ${({ details }) => (details ? '#f6f6f6' : '#1C771C')};
  border: 1px solid ${({ details }) => (details ? '#888888' : '#1C771C')};
  border-radius: 4px;
  padding: 15px 25px;
  cursor: pointer;
  font-weight: ${({ details }) => (details ? '400' : '700')};
  text-transform: ${({ details }) => (details ? 'none' : 'uppercase')};
  font-size: 16px;
  color: ${({ details }) => (details ? '#888888' : '#ffffff')};
  text-decoration: none;
  min-width: 150px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ details }) => (details ? '#dddddd' : '#1C771C')};
  }

  @media (max-width: 635px) {
    width: calc(100% - 55px);
  }
`;

const LogoNoLinkStyled = styled(LogoNoLink)`
  margin-bottom: 40px;

  @media (max-width: 635px) {
    margin-bottom: 20px;
  }
`;

const InternalLink = styled.span`
  color: #333333;
  text-decoration: underline;
  cursor: pointer;
`;

const Details = styled.div`
  background: #f6f6f6;
  padding: 40px;
  margin: 0 -40px;
  margin-top: 20px;

  @media (max-width: 635px) {
    padding: 20px;
    margin: 0 -20px;
  }
`;

const DetailsContent = styled.div`
`;

const Flex = styled.div`
  display: flex;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
  align-items: flex-start;
  margin-bottom: 40px;
  gap: 15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ two }) => (two ? '120px 6fr' : '120px 5fr 1fr')};
  gap: 15px;
  margin-bottom: 20px;
`;

const ToggleSwitch = styled.div`
  width: 50px;
  height: 25px;
  background: #dddddd;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    background: #ffffff;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  ${({ checked }) => (checked && `
    background: #1C771C;

    &:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  `)}
`;

const AboutContent = styled.div`
  background: #ffffff;
  padding: 40px;
  padding-top: 0px;
  margin: 0 -40px;

  @media (max-width: 635px) {
    padding: 20px;
    padding-top: 0px;
    margin: 0 -20px;
  }
`;

const CookieInfosContainer = styled.div`

`;
const CookieInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
`;

const ToggleParagraph = styled(Paragraph)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Cookie = ({
  name,
  purpose,
  duration,
  owner,
  privacyPolicy,
}) => {
  return (
    <CookieInfoContainer>
      <Paragraph bold>Formål:</Paragraph>
      <Paragraph>{purpose}</Paragraph>
      <Paragraph bold>Varighed:</Paragraph>
      <Paragraph>{duration}</Paragraph>
      <Paragraph bold>Ejer:</Paragraph>
      <Paragraph>{owner}</Paragraph>
      <Paragraph bold>Navn:</Paragraph>
      <Paragraph>{name}</Paragraph>
      <Paragraph bold>Privatlivspolitik:</Paragraph>
      <Link href={privacyPolicy} target="_blank" rel="noopener noreferrer">Privatlivspolitik</Link>
    </CookieInfoContainer>
  );
};

const CookieOverlayModal = () => {
  const [isOpen, setIsOpen] = useState(consentValue() === null);
  const [showingDetails, setShowingDetails] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const [statisticsChecked, setStatisticsChecked] = useState(false);
  const [showingNessesary, setShowingNessesary] = useState(false); // eslint-disable-line
  const [showingMarketing, setShowingMarketing] = useState(false); // eslint-disable-line
  const [showingStatistics, setShowingStatistics] = useState(false); // eslint-disable-line
  const [showAbout, setShowAbout] = useState(false);
  const toggleAbout = () => {
    setShowAbout((s) => !s);
    if (showingDetails) {
      onToggleDetails();
    }
  };
  const onToggleDetails = () => {
    setShowingDetails((s) => !s);
    if (showAbout) {
      toggleAbout();
    }
  };
  const saveSettings = () => {
    let actualMarketing = marketingChecked;
    let actualStatistics = statisticsChecked;
    if (!showingDetails) {
      actualMarketing = true;
      actualStatistics = true;
    }
    chooseOptInOrOut({ marketing: actualMarketing, analytics: actualStatistics });
    optScripts();
    setIsOpen(false);
    if (window.updateCookiesPage) {
      window.updateCookiesPage();
    }
    // log the consent
    window.axios.post(`${window.location.origin}/api/consentLog`, { consentAnalytics: actualStatistics ? 1 : 0, consentMarketing: actualMarketing ? 1 : 0 }).then(() => { }).catch(() => { }); // eslint-disable-line
  }
  const anyChosen = marketingChecked || statisticsChecked;
  const toggleAll = () => {
    if (anyChosen) {
      setMarketingChecked(false);
      setStatisticsChecked(false);
    } else {
      setMarketingChecked(true);
      setStatisticsChecked(true);
    }
  };
  useEffect(() => {
    window.onConsentReset = () => {
      setIsOpen(true);
      setMarketingChecked(false);
      setStatisticsChecked(false);
    }
    return () => delete window.onConsentReset;
  });
  // Disable body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);
  return (
    <StyledModal
      isOpen={isOpen}
    >
      <Wrap>
        <CookieContent showingDetails={showingDetails}>
          <LogoNoLinkStyled title="Skafte Øl &amp; Vand" text1="Skafte Øl &amp; Vand" text2="Leverandør af fadøl, vand, øl & sodavand" />
          {showAbout ? (
            <React.Fragment>
              <Heading>
                <CookiesPolitikTitle />
              </Heading>
              <CookiesPolitikSubTitle />
              <AboutContent>
                <CookiesPolitikContent isModal onToggleDetails={onToggleDetails} />
              </AboutContent>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Heading>
                {showingDetails ? "Indstillinger" : "Skafte.dk bruger cookies"}
              </Heading>
              <Paragraph>
                Vi bruger teknologier, herunder cookies, til at indsamle oplysninger om dig til forskellige formål, herunder:
              </Paragraph>
              <Paragraph marginTop="30px" marginBottom="30px">
                Statistiske<br />
                Marketing
              </Paragraph>
              <Paragraph>
                Ved at trykke på &apos;Accepter alle&apos; giver du samtykke til alle disse formål. Du kan også vælge at tilkendegive, hvilke formål du vil give samtykke til ved at benytte checkboksene ud for formålet, og derefter trykke på &apos;Gem indstillinger&apos;.
              </Paragraph>
              <Paragraph>
                Du kan læse mere om vores brug af cookies og andre teknologier, samt om vores indsamling og behandling af personoplysninger ved at trykke på linket.
              </Paragraph>
              <InternalLink onClick={toggleAbout}>
                Om cookies
              </InternalLink>
              <Spacer size={30} />
            </React.Fragment>
          )}
          {showingDetails && (
          <Details>
            <Flex right>
              <InternalLink onClick={toggleAll}>
                {anyChosen ? 'Fravælg alle' : 'Vælg alle'}
              </InternalLink>
            </Flex>
            <DetailsContent>
              <Grid two>
                <Div>
                  <ToggleParagraph bold onClick={() => setShowingNessesary((s) => !s)}>
                    {showingNessesary ? <BsCaretDownFill /> : <BsCaretRightFill />} Nødvendige
                  </ToggleParagraph>
                </Div>
                <Paragraph fontSize="14px">
                  Nødvendige cookies hjælper med at gøre en hjemmeside brugbar ved at aktivere grundlæggende funktioner, såsom side-navigation og adgang til sikre områder af hjemmesiden. Hjemmesiden kan ikke fungere optimalt uden disse cookies.
                </Paragraph>
              </Grid>
              {showingNessesary && (
                <CookieInfosContainer>
                  {cookieContent.nessesary.map((cookie) => (
                    <Cookie key={cookie.name} {...cookie} />
                  ))}
                </CookieInfosContainer>
              )}
              <Grid>
                <Div>
                  <ToggleParagraph bold onClick={() => setShowingStatistics((s) => !s)}>
                    {showingStatistics ? <BsCaretDownFill /> : <BsCaretRightFill />} Statistik
                  </ToggleParagraph>
                </Div>
                <Paragraph fontSize="14px">
                  Vi indsamler oplysninger om dine interesser, herunder hvilke sider og annoncer du klikker på, hvilke produkter eller ydelser du viser interesse for, eller køber, på denne og andre hjemmesider.
                </Paragraph>
                <Div textRight>
                  <ToggleSwitch checked={statisticsChecked} onClick={() => setStatisticsChecked(!statisticsChecked)} />
                </Div>
              </Grid>
              {showingStatistics && (
                <CookieInfosContainer>
                  {cookieContent.statistics.map((cookie) => (
                    <Cookie key={cookie.name} {...cookie} />
                  ))}
                </CookieInfosContainer>
              )}
              <Grid>
                <Div>
                  <ToggleParagraph bold onClick={() => setShowingMarketing((s) => !s)}>
                    {showingMarketing ? <BsCaretDownFill /> : <BsCaretRightFill />} Marketing
                  </ToggleParagraph>
                </Div>
                <Paragraph fontSize="14px">
                  Vi indsamler oplysninger om dine interesser, herunder hvilke sider og annoncer du klikker på, hvilke produkter eller ydelser du viser interesse for, eller køber, på denne og andre hjemmesider.
                </Paragraph>
                <Div textRight>
                  <ToggleSwitch checked={marketingChecked} onClick={() => setMarketingChecked(!marketingChecked)} />
                </Div>
              </Grid>
              {showingMarketing && (
                <CookieInfosContainer>
                  {cookieContent.marketing.map((cookie) => (
                    <Cookie key={cookie.name} {...cookie} />
                  ))}
                </CookieInfosContainer>
              )}
            </DetailsContent>
          </Details>
            )}
          <CookieButtons>
            <CookieButton details onClick={onToggleDetails}>
              {showingDetails ? 'Skjul detaljer' : 'Vis detaljer'}
            </CookieButton>
            <CookieButton onClick={saveSettings}>
              {showingDetails ? 'Gem indstillinger' : 'Accepter alle'}
            </CookieButton>
          </CookieButtons>
        </CookieContent>
      </Wrap>
    </StyledModal>
  )
};

export default CookieOverlayModal;

import React from 'react';
import styled from 'styled-components/macro';
import { Row } from 'react-grid-system';
import { Col, Container } from '../../../components/grid';
import {
  Bold,
  Paragraph,
  Hr,
} from '../../../components/typography';
import { Price } from '../../../containers/commerce';
import SkafteMap from '../../../components/Map/SkafteMap';

const OmraaderContent = styled.div`
  column-count: 4;

  @media (max-width: 1199px) {
    column-count: 3;
  }

  @media (max-width: 575px) {
    column-count: 2;
  }
`;

const Section2 = () => (
  <React.Fragment>
    <Container white mt mb>
      <Row>
        <Col xs={12} lg={5} xl={4}>
          <Row>
            <Col xs={12} paddingTop="0px">
              <SkafteMap />
            </Col>
            <Col xs={12} paddingBottom="0px">
              <Paragraph print="margin-top: 50px;" marginBottom="0px">Paller med kildevand og kulsyre sendes fragtfrit i hele Danmark (brofaste øer)</Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={7} xl={8}>
          <Row>
            <Col xs={12} paddingTop="0">
              <Paragraph fontSize="16px">
                Vi sælger til <Bold>private</Bold>, <Bold>erhverv</Bold>, samt <Bold>offentlige virksomheder</Bold>, også EAN
              </Paragraph>
              <Paragraph marginBottom="0">
                Gratis levering i nedenstående områder ved min. 5 kasser!<br />
                Levering og afhentning af lejet fadølsanlæg koster <Price varenr="621" momsIndication />
              </Paragraph>
            </Col>
            <Col xs={12}>
              <OmraaderContent>
                Albertslund<br />
                Allerød<br />
                Amager<br />
                Bagsværd<br />
                Ballerup<br />
                Birkerød<br />
                Brøndby<br />
                Brøndby Strand<br />
                Brønshøj<br />
                Byen<br />
                Centrum<br />
                Charlottenlund<br />
                City<br />
                Dragør<br />
                Farum<br />
                Fredensborg<br />
                Frederiksberg<br />
                Frederiksberg C<br />
                Gentofte<br />
                Glostrup<br />
                Greve<br />
                Havdrup<br />
                Hedehusene<br />
                Hellerup<br />
                Herlev<br />
                Hillerød<br />
                Holmen<br />
                Holte<br />
                Humlebæk<br />
                Hvidovre<br />
                Hørsholm<br />
                Indre by<br />
                Ishøj<br />
                Jyllinge<br />
                Karlslunde<br />
                Kastrup<br />
                Klampenborg<br />
                Kokkedal<br />
                København<br />
                København K<br />
                København N<br />
                København NV<br />
                København S<br />
                København SV<br />
                København V<br />
                København Ø<br />
                Lyngby<br />
                Lynge<br />
                Måløv<br />
                Nivå<br />
                Nærum<br />
                Nørrebro<br />
                Roskilde<br />
                Rungsted<br />
                Rungsted Kyst<br />
                Rødovre<br />
                Skovlunde<br />
                Smørum<br />
                Solrød Strand<br />
                Stenløse<br />
                Store Magleby<br />
                Sydhavnen<br />
                Søborg<br />
                Søndermarken<br />
                Tingbjerg<br />
                Tårbæk<br />
                Taarnby<br />
                Tåstrup<br />
                Taastrup<br />
                Tune<br />
                Valby<br />
                Vallensbæk<br />
                Vallensbæk Strand<br />
                Vangede<br />
                Vanløse<br />
                Vedbæk<br />
                Veksø<br />
                Vestegnen<br />
                Vesterbro<br />
                Virum<br />
                Værløse<br />
                Ølstykke<br />
                Ørestaden<br />
                Østerbro<br />
              </OmraaderContent>
            </Col>
          </Row>
          <Hr />
          <Row>
            <Col xs={12}>
              <Paragraph>Levering og afhentning i følgende områder, foretages mod et tillæg på <Price varenr="634" momsIndication /></Paragraph>
              <Paragraph marginBottom="0">Vi kører ikke dagligt i disse områder - telefonisk aftale er påkrævet efter bestilling</Paragraph>
            </Col>
            <Col xs={12}>
              <OmraaderContent>
                Borup<br />
                Dronningmølle<br />
                Esrum<br />
                Frederikssund<br />
                Gilleleje<br />
                Græsted<br />
                Gørløse<br />
                Helsinge<br />
                Helsingør<br />
                Hornbæk<br />
                Hundested<br />
                Hvalsø<br />
                Jægerspris<br />
                Kregme<br />
                Køge<br />
                Lejre<br />
                Lille Skensved<br />
                Liseleje<br />
                Melby<br />
                Ramløse<br />
                Skævinge<br />
                Slangerup<br />
                Snekkersten<br />
                Tisvildeleje<br />
                Viby Sjælland<br />
                Ølsted<br />
                Ålsgårde<br />
              </OmraaderContent>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Section2;

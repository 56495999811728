import React from "react";
import styled from "styled-components/macro";
import GodkendTabButton from "../layout/MainBestilLayout/GodkendTabButton";
import TabButtonContainer from "../layout/MainBestilLayout/TabButtonContainer";
import { FaCog, ImTruck } from "../../../components/Icons";
import { TabButtonWrap, TabFlexDiv } from "../TabsComponents";

const IconContainer = styled.div`
  & svg {
    height: 20px;
    width: 20px;

    @media (max-width: 400px) {
      height: 15px;
      width: 15px;
    }
  }
`;

const tabs = [
  {
    id: "fadoel",
    to: "/bestil/fadoel-og-anlaeg/fadoel",
    title: "Fadøl",
    mediumTitle: "Fadøl",
    smallTitle: "Fadøl",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "anlaeg-og-bar",
    to: "/bestil/fadoel-og-anlaeg/anlaeg-og-bar",
    title: "Anlæg og bar",
    mediumTitle: "Anlæg og bar",
    smallTitle: "Anlæg",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "oelglas",
    to: "/bestil/fadoel-og-anlaeg/oelglas",
    title: "Is & Glas",
    mediumTitle: "Is & Glas",
    smallTitle: "Is & Glas",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "reservedele",
    to: "/bestil/fadoel-og-anlaeg/reservedele",
    title: "Reservedele",
    mediumTitle: "Reservedele",
    smallTitle: (
      <React.Fragment>
        <FaCog />
      </React.Fragment>
    ),
    showSmall: true,
    showMedium: true,
  },
  {
    id: "levering",
    to: "/bestil/fadoel-og-anlaeg/levering",
    title: "Levering",
    mediumTitle: (
      <IconContainer>
        <ImTruck />
      </IconContainer>
    ),
    smallTitle: (
      <IconContainer>
        <ImTruck />
      </IconContainer>
    ),
    showSmall: true,
    showMedium: true,
  },
];

export default ({ activeTab }) => (
  <TabButtonWrap>
    <TabFlexDiv>
      <TabButtonContainer activeTab={activeTab} tabs={tabs} />
    </TabFlexDiv>
    <GodkendTabButton to="/bestil/detaljer" title="Gå til kassen" />
  </TabButtonWrap>
);

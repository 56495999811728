import React from 'react';
import styled from 'styled-components/macro';
import { Paragraph } from '../typography';
import { Div } from '../grid';
import { ScreenSizeDetector } from '../../lib/helpers';
import MapSkafte from './MapSkafte';
import ImageSimple from '../Image/ImageSimple';

const MapImageContainer = styled(Div)`
  position: relative;
  display: block;
  width: 100%;
  height: 600px;
  background: transparent;
  overflow: hidden;

  @media (max-width: 991px) {
    height: 400px;
  }
`;
const MapImageChildContainer = styled.div`
  position: absolute;
  bottom: 20px;
  display: block;
  background: ${({ theme }) => theme.colors.colorOffWhite};
  padding: 15px;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  z-index: 1;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) inset;
  }

  & p {
    color: ${({ theme }) => theme.colors.colorGreyDark};
    margin-bottom: 0px;
    font-size: 20px;
    text-align: center;
  }
`;

const MapImage = styled(ImageSimple)`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MapImageWrap = ({
  children,
  fullWidth,
  ...otherProps
}) => {
  if (fullWidth) {
    return (
      <MapImageContainer {...otherProps}>
        <MapImageChildContainer>
          {children}
        </MapImageChildContainer>
        <MapImage name='style.skafteMapFull.png' />
      </MapImageContainer>
    );
  }

  return (
    <MapImageContainer {...otherProps}>
      <MapImageChildContainer>
        {children}
      </MapImageChildContainer>
      <MapImage name='style.skafteMap.jpg' imageWidth={690} />
    </MapImageContainer>
  );
};

const HoverableMapImage = styled(MapImageWrap)`
  &:hover {
    cursor: pointer;
  }
`;

export default class SkafteMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapInitiated: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({ mapInitiated: true });
  }

  render() {
    const { mapInitiated } = this.state;
    const { fullWidth } = this.props;

    if (!mapInitiated) {
      return (
        <HoverableMapImage onClick={this.onClick} role="button" fullWidth={fullWidth}>
          <Paragraph>
            Tryk på kortet for at interagere med det
          </Paragraph>
        </HoverableMapImage>
      );
    }
    return (
      <ScreenSizeDetector MapHeight={[[991, '400px'], '600px']}>
        <MapSkafte />
      </ScreenSizeDetector>
    );
  }
}

import React from "react";
import moment from "moment";
import HideAtDate from "./HideAtDate";
import { Table, Td } from "./tables";

const dates = [
  ["23/12/2024", "8-16"],
  ["24/12/2024", "Lukket"],
  ["25/12/2024", "Lukket"],
  ["26/12/2024", "Lukket"],
  ["27/12/2024", "8-12"],
  ["28/12/2024", "Lukket"],
  ["29/12/2024", "Lukket"],
  ["30/12/2024", "8-12"],
  ["31/12/2024", "Lukket"],
  ["01/01/2025", "Lukket"],
  ["02/01/2025", "Lukket"],
  ["03/01/2025", "8-12"],
];

export default () => (
  <React.Fragment>
    <HideAtDate
      date={moment(`${dates[dates.length - 1][0]} 23:59`, "DD/MM/YYYY HH:mm")}
    >
      <Table paddingTd="2px 0" fontSize="12px" lines1>
        {dates.map((dateArray) => {
          const dateString = dateArray[0];
          const status = dateArray[1];
          const parts = dateString.split("/");
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // months are 0-indexed
          const year = parseInt(parts[2], 10);
          const date = new Date(year, month, day);
          const weekdays = [
            "Søndag",
            "Mandag",
            "Tirsdag",
            "Onsdag",
            "Torsdag",
            "Fredag",
            "Lørdag",
          ];
          const weekday = weekdays[date.getDay()]; // Returns "Tor"
          const momentDate = moment(`${dateString} 23:59`, "DD/MM/YYYY HH:mm");
          return (
            <HideAtDate date={momentDate}>
              <tr key={weekday}>
                <Td width="50px">{weekday}</Td>
                <Td>{dateString}:</Td>
                <Td textRight>{status}</Td>
              </tr>
            </HideAtDate>
          );
        })}
      </Table>
    </HideAtDate>
  </React.Fragment>
);

import React, { useEffect } from 'react';

const MapSkafte = () => {
  // Replace 'YOUR_GOOGLE_MAPS_API_KEY' with your actual API key
  const apiKey = 'AIzaSyDms2Ir2jzVMzkvLY5R0PN6XWhWHORbvyo';

  useEffect(() => {
    // Load the Google Maps JavaScript API script dynamically
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', initializeMap);
    document.body.appendChild(script);

    return () => {
      // Remove the script to avoid potential memory leaks
      document.body.removeChild(script);
    };
  }, [apiKey]);

  const initializeMap = () => {
    // Initialize the map when the Google Maps API is loaded
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 55.651852971378545, lng: 12.38246951760058 },
      zoom: 8.5,
    });

    // Fetch GeoJSON data from MapTiler API endpoint
    fetch('https://api.maptiler.com/data/c8540147-db92-4f4e-b788-03356e2a2bfd/features.json?key=Q5ZYGeM2JwYrwgKZdXOw')
      .then((response) => response.json())
      .then((geoJsonData) => {
        // Create a data layer and add the GeoJSON data to it
        const dataLayer = new window.google.maps.Data();
        dataLayer.addGeoJson(geoJsonData);
        dataLayer.setMap(map);

        // You can style the displayed features using the data layer's styling options
        dataLayer.setStyle({
          fillColor: '#4A728A',
          fillOpacity: 0.5,
          strokeColor: '#4A728A',
          strokeWeight: 2,
        });

        // Create a marker for your company
        const marker = new window.google.maps.Marker({
          position: { lat: 55.651845, lng: 12.382469 },
          map: map,
          title: 'Skafte Øl & Vand',
        });

        // Add a click event listener to the marker
        marker.addListener('click', () => {
          // Open a new tab with Google Maps showing your company
          window.open('https://www.google.com/maps?q=Skafte+Øl+&+Vand', '_blank');
        });
      })
      .catch((error) => {
        console.error('Error fetching GeoJSON data:', error);
      });
  };

  return <div id="map" style={{ width: '100%', height: '600px' }} />;
};

export default MapSkafte;

import React from "react";
import classNames from "classnames";
import { Route, Switch, Link } from "react-router-dom";
import styled from "styled-components/macro";
import { FiArrowRight } from "../../../../components/Icons";

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    font-size: 20px;
    fill: ${({ theme }) => theme.colors.colorWhite};
  }
`;

const LeveringButtonNoProps = ({ title, className, ...props }) => (
  <Link className={classNames(className, "godkendOrdreButton")} {...props}>
    {title}{" "}
    <StyledIcon>
      <FiArrowRight />
    </StyledIcon>
  </Link>
);

const StyledLeveringButton = styled(LeveringButtonNoProps)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.2s ease;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-decoration: none;
  width: 215px;
  transition: background-color 0.5s ease;
  background-color: ${({ theme }) => theme.colors.colorGreen};
  color: ${({ theme }) => theme.colors.colorWhite};
  &:hover {
    background-color: ${({ theme }) => theme.hoverColors.colorGreen};
  }
  @media (max-width: 1199px) {
    width: 170px;
    font-size: 13px;
  }
  @media (max-width: 991px) {
    width: calc(100% - 40px);
    border-top: 10px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export default ({ to, ...props }) => (
  <Switch>
    <Route
      path={to}
      exact
      render={() => <StyledLeveringButton to={to} active {...props} />}
    />
    <Route render={() => <StyledLeveringButton to={to} {...props} />} />
  </Switch>
);

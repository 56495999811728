import React from 'react';
import styled from 'styled-components/macro';
import { LinkStyled, Paragraph } from '../components/typography';

export const HeadingAbout = styled.h4`
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 15px;
  padding-top: 30px;
`;

export const CookiesPolitikTitle = () => (
  <React.Fragment>Cookiepolitik</React.Fragment>
);

export const CookiesPolitikSubTitle = () => (
  <React.Fragment>
    <Paragraph bold>
      Dit samtykke gælder for følgende domæner:
    </Paragraph>
    <Paragraph>skafte.dk</Paragraph>
    <Paragraph bold marginBottom="30px">
      Cookiepolitikken er sidst opdateret d. 16.12.2023
    </Paragraph>
  </React.Fragment>
);

export const CookiesPolitikContent = ({ onToggleDetails, isModal }) => (
  <React.Fragment>
    <HeadingAbout>Hvad er en cookie?</HeadingAbout>
    <Paragraph>
      En cookie er en lille datafil, som bliver gemt på din computer, tablet eller mobiltelefon. En cookie er ikke et program, der kan indeholde skadelige programmer eller virus.
    </Paragraph>
    <HeadingAbout>Hjemmesidens brug af cookies</HeadingAbout>
    <Paragraph>
      Cookies kan være nødvendige for at få hjemmesiden til at fungere. Cookies hjælper os desuden med at få overblik over dit besøg på hjemmesiden, så vi løbende kan optimere og målrette hjemmesiden til dine behov og interesser. Cookies husker f.eks., hvad du har lagt i en eventuel indkøbskurv, om du tidligere har besøgt siden, om du er logget ind og hvilket sprog og valuta, du gerne vil have vist på hjemmesiden. Vi bruger også cookies til at målrette vores annoncer over for dig på andre hjemmesider. Helt overordnet anvender vi cookies som del af vores service til at vise indhold, der er så relevant som muligt for dig.
    </Paragraph>
    <HeadingAbout>Hvor længe bliver cookies gemt?</HeadingAbout>
    <Paragraph>
      Det varierer, hvor længe en bestemt cookie bliver opbevaret på dine enheder og browsere. En cookies levetid beregnes ud fra dit seneste besøg på hjemmesiden. Når cookiens levetid udløber, bliver den automatisk slettet.
    </Paragraph>
    <HeadingAbout>Sådan afviser eller sletter du cookies</HeadingAbout>
    <Paragraph>
      Du kan altid afvise alle -/tredjepartscookies fuldstændig ved at ændre indstillingerne i din browser på din computer, tablet eller telefon. Hvor du finder indstillingerne afhænger af, hvilken browser du anvender. Du skal dog være opmærksom på, at hvis du afviser alle -/tredjepartscookies, vil der være funktioner og services, du ikke kan anvende på hjemmesiden (fordi disse er afhængige af cookies). <LinkStyled external to="https://tools.google.com/dlpage/gaoptout">Du kan fravælge cookies fra Google Analytics her</LinkStyled>.
    </Paragraph>
    <HeadingAbout>Hvordan sletter man cookies?</HeadingAbout>
    <Paragraph>
      Cookies du tidligere har accepteret, kan sagtens slettes. Hvordan du sletter disse afhænger af den browser du bruger (Chrome, Firefox, Safari etc.) og på hvilken enhed (mobil, tablet, pc, mac). Det er typisk under Indstillinger - Sikkerhed og Privatliv, men kan variere fra browser til browser. Hvilken enhed/browser bruger du - klik på det relevante link:
    </Paragraph>
    <Paragraph>
      <LinkStyled external to="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">Internet Explorer</LinkStyled><br />
      <LinkStyled external to="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies">Microsoft Edge</LinkStyled><br />
      <LinkStyled external to="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">Mozilla Firefox</LinkStyled><br />
      <LinkStyled external to="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</LinkStyled><br />
      <LinkStyled external to="https://www.opera.com/help/tutorials/security/cookies">Opera</LinkStyled><br />
      <LinkStyled external to="https://support.apple.com/en-us/HT201265">Safari</LinkStyled><br />
      <LinkStyled external to="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">Flash cookies</LinkStyled><br />
      <LinkStyled external to="https://support.apple.com/en-us/HT1677">Apple</LinkStyled><br />
      <LinkStyled external to="https://timeread.hubpages.com/hub/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device">Android</LinkStyled><br />
      <LinkStyled external to="https://support.microsoft.com/en-us/help/11696/windows-phone-7">Windows 7</LinkStyled><br />
    </Paragraph>
    {isModal && (
      <React.Fragment>
        <HeadingAbout>Ændring af dit samtykke</HeadingAbout>
        <Paragraph>
          Du ændrer dit samtykke ved enten at slette cookies fra browseren eller ved at ændre dit oprindelige valg ved at klikke på linket herunder:
        </Paragraph>
        <Paragraph>
          <LinkStyled action onClick={onToggleDetails}>Du ændrer dit samtykke ved at klikke her</LinkStyled><br />
          Husk: Bruger du flere browsere, skal du slette cookies i dem alle.
        </Paragraph>
      </React.Fragment>
    )}
    <HeadingAbout>Har du spørgsmål?</HeadingAbout>
    <Paragraph>
      Har du kommentarer eller spørgsmål i forbindelse med vores information og/eller behandling af personlige oplysninger, er du velkommen til at kontakte os.
    </Paragraph>
  </React.Fragment>
);
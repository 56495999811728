import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { Row, Col } from '../grid';
import {
  Heading3, Paragraph, LinkButton,
} from '../typography';
import Modal from '../Modal';
import { acceptHerslev } from '../../features/commerce/bucket/actions';
import { isOfferingHerslevOffer } from '../../features/commerce/bucket/selectors';
import { Image } from '../Image';
import { Price } from '../../containers/commerce';

const StyledModal = styled(Modal)`
  z-index: 99999999999;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.colors.colorWhite};
  border: 2px ${({ theme }) => theme.colors.colorGreyLightAlt} solid;
  outline: none;
  overflow-y: hidden;
  overflow-x: hidden;
  
  width: 550px;
  padding: 20px;

  @media (max-width: 991px) {
    width: 400px;
    padding: 10px;
  }

  @media (max-width: 767px) {
    width: 280px;
    padding: 10px;
  }

  @media (max-height: 572px) {
    top: 100px !important;
    height: calc(80% - 100px);
    margin-top: 0px;
    transform: translate(-50%, 0) !important;
    overflow-y: scroll;
  }
`;
const HerslevOfferModal = ({ isOpen, onNo, onYes }) => (
  <StyledModal
    isOpen={isOpen}
    onRequestClose={onNo}
  >
    <Row>
      <Col xs={12} md={3} textCenter>
        <Image name="produkter-fadoel.herslev-bryghus-gaveaeske.png" maxHeight="200px" />
      </Col>
      <Col xs={12} md={9}>
        <Heading3>Gratis smagskasse, værdi <Price varenr="10000" /></Heading3>
        <Paragraph>
          Når du køber mindst 2 fustager fra Herslev Bryghus kan du få en gratis smagskasse med din ordre.
        </Paragraph>
        <Paragraph>
          Smagskassen indeholder Herslev Øko Juleøl, Herslev Øko Stjernebryg og Herslev Øko Snefri (0,5%)
        </Paragraph>
        <Paragraph>Max 1 gratis smagskasse pr. ordre.</Paragraph>
        <Paragraph>Pant bliver lagt til i kurven.</Paragraph>
        <Paragraph bold fontSize="16px">
          Ønsker du en gratis smagskasse fra Herslev Bryghus med til din bestilling?
        </Paragraph>
        <Row>
          <Col xs={6}>
            <LinkButton action onClick={onYes}>
              Ja tak
            </LinkButton>
          </Col>
          <Col xs={6}>
            <LinkButton action red onClick={onNo}>
              Nej tak
            </LinkButton>
          </Col>
          <Col xs={12}>
            <LinkButton small to="/bestil/flasker-og-daaser/oel-og-alkohol/kategori/oel-33cl-glas/vare/10000" onClick={onYes}>
              Jeg ønsker en gratis smagskasse og vil gerne købe nogle flere
            </LinkButton>
          </Col>
        </Row>
      </Col>
    </Row>
  </StyledModal>
);

export default connect(
  state => ({
    isOpen: isOfferingHerslevOffer(state),
  }),
  dispatch => ({
    onYes: () => dispatch(acceptHerslev(true)),
    onNo: () => dispatch(acceptHerslev(false)),
  }),
)(HerslevOfferModal);

import React from "react";
import styled from "styled-components/macro";
import { withRouter, Link } from "react-router-dom";
import { Row } from "react-grid-system";
import { Image } from "../../../components/Image";
import { Col, Container, Div } from "../../../components/grid";
import {
  Bold,
  Paragraph,
  Heading3,
  Span,
  LinkStyled,
} from "../../../components/typography";
import { ScreenSizeDetector } from "../../../lib/helpers";
import CookieOverlayModal from "../../../components/CookieOverlayModal";
import Datoer from "../../../components/Datoer";
import RoyalUnibrewOfferModal from "../../../components/RoyalUnibrewOfferModal";
import HerslevOfferModal from "../../../components/HerslevOfferModal";
import Section2 from "./Section2";
import Aabningstider from "./Aabningstider";
import HeinekenOfferModal from "../../../components/HeinekenOfferModal";

const InvisibleLink = styled(Link)`
  display: none;
`;

const Footer = ({ kontaktFooter }) => (
  <React.Fragment>
    <RoyalUnibrewOfferModal />
    <HeinekenOfferModal />
    <HerslevOfferModal />
    {!kontaktFooter && (
      <Container white mt mb>
        <Row>
          <Col xs={12} md={7} lg={6}>
            <Row>
              <Col xs={12} sm={6} md={5} lg={6}>
                <Heading3>Peter Skafte ApS</Heading3>
                <Paragraph>
                  Park Allé 352A, 2605 Brøndby
                  <br />
                  <LinkStyled anchor to="mailto:peter@skafte.dk">
                    peter@skafte.dk
                  </LinkStyled>{" "}
                  - CVR: 14290400
                </Paragraph>
                <Paragraph margin="20px 0 0 0">
                  <Bold>Bankforbindelse</Bold>
                  <br />
                  Danske Bank
                  <br />
                  Reg./konto: 4440 3557061940
                </Paragraph>
                <Paragraph margin="20px 0 0 0">
                  Swift: DABADKKK
                  <br />
                  IBAN kode:
                  <br />
                  DK 1230003557061940
                </Paragraph>
              </Col>
              <Col xs={12} sm={6} md={7} lg={6}>
                <Heading3>Tlf: 43 27 00 00</Heading3>
                <Paragraph bold>Åbningstider</Paragraph>
                <Aabningstider />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} lg={6} overflow>
            <Row>
              <Col xs={12}>
                <Heading3>
                  <Datoer type="experience" /> års erfaring
                </Heading3>
                <ScreenSizeDetector maxWidth={[[1200, "100%"], "250px"]}>
                  <Paragraph>
                    Peter Skafte har været indehaver
                    <br />
                    af Skafte Øl &amp; Vand siden 1984.
                  </Paragraph>
                </ScreenSizeDetector>
                <ScreenSizeDetector
                  maxWidth={[[992, "100%"], [1200, "245px"], "280px"]}
                >
                  <Paragraph maxWidth="280px">
                    Peter og Gitte passer telefon og administration. Rane tager
                    sig af lagerekspeditioner, samt teknik. Troels ordner alt
                    med web og IT. 5 chauffører sørger for udbringning af øl,
                    vand og fadølsanlæg til såvel faste kunder, som
                    privatpersoner, firmaer og offentlige virksomheder.
                  </Paragraph>
                </ScreenSizeDetector>
                <ScreenSizeDetector margin={[[992, "0 0 130px 0"], ""]}>
                  <Div>
                    <LinkStyled
                      external
                      noStyle
                      to="http://www.findsmiley.dk/90927"
                      alt="Kontrolrapport"
                      aria-label="Kontrolrapport"
                    >
                      <Image
                        name="style.kontrol-rapport.gif"
                        imageWidth="100px"
                        maxWidth="100px"
                        alt="Kontrolrapport"
                      />
                    </LinkStyled>
                  </Div>
                </ScreenSizeDetector>
                <Div>
                  <ScreenSizeDetector
                    imageWidth={[[991, "235px"], [1199, "260px"], "340px"]}
                    bottom={[[767, "-167px"], "-116px"]}
                  >
                    <Image
                      absolute
                      name="style.skafte-kildevand2.png"
                      alt="Skafte med kildevand"
                      right="-25px"
                    />
                  </ScreenSizeDetector>
                </Div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )}
    <Section2 />
    <Div textCenter>
      <Paragraph textCenter>
        <LinkStyled to="/cookies">Cookies</LinkStyled>
        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
        <LinkStyled to="/link-til-lokaler-mad-og-udlejning">Links</LinkStyled>
        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
        <LinkStyled to="/sitemap">Sitemap</LinkStyled>
      </Paragraph>
      <Div itemScope="" itemType="https://schema.org/Store">
        <Image
          itemProp="image"
          name="style.peter-skafte.png"
          style={{ display: "none" }}
        />
        &copy; Copyright 1984 - 2022&nbsp;
        <Span itemProp="name">Peter Skafte ApS</Span>&nbsp;
        <Span itemProp="priceRange" hide>
          $$
        </Span>
        <Div
          itemProp="address"
          itemScope=""
          itemType="http://schema.org/PostalAddress"
        >
          Adresse:<Span itemProp="streetAddress">Park Allé 352A</Span>,&nbsp;
          <Span itemProp="addressLocality">Brøndby</Span>,&nbsp;
          <Span itemProp="addressRegion">Hovedstaden</Span>,&nbsp;
          <Span itemProp="postalCode">2605</Span>,&nbsp;
          <Span itemProp="addressCountry">DK</Span> | Tel:&nbsp;
          <Span itemProp="telephone">+45 43270000</Span> | Email:&nbsp;
          <LinkStyled anchor to="mailto:peter@skafte.dk" itemProp="email">
            peter@skafte.dk
          </LinkStyled>
          .
        </Div>
        <Span
          itemProp="geo"
          itemScope=""
          itemType="http://schema.org/GeoCoordinates"
        >
          <meta itemProp="latitude" content="55.651847" />
          <meta itemProp="longitude" content="12.382469" />
        </Span>
      </Div>
    </Div>
    <InvisibleLink id="bestilLink" to="/bestil/flasker-og-daaser/kildevand">
      1
    </InvisibleLink>
    <InvisibleLink id="vandMedLogoLink" to="/kildevand/vand-med-logo">
      2
    </InvisibleLink>
    <InvisibleLink id="returPantLink" to="/bestil/returpant">
      3
    </InvisibleLink>
    <CookieOverlayModal />
  </React.Fragment>
);

const kontaktLocations = ["/kontakt-os"];
export default withRouter(({ location: { pathname } }) => (
  <Footer kontaktFooter={kontaktLocations.includes(pathname)} />
));

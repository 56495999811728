import React from 'react';
import styled from 'styled-components/macro';
import VisibilitySensor from 'react-visibility-sensor';
import webp from 'check-for-webp';
import getImageRequestName, { getImageRequestNameWebp } from '../../images';
import getImageInfo from '../../getImageInfo';
import getArg from '../../lib/args';

const NormalImage = styled.img`
`;
const EmptyDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const Img = ({
  visible,
  imgComponent: Component,
  ...props
}) => {
  if (!getArg('browser') && getArg('isPageSpeed')) {
    const { src, ...otherProps } = props;
    return <Component src="/img/blank.gif" data-echo={src} {...otherProps} />;
  }
  if (visible) {
    return <Component {...props} />;
  }
  return <EmptyDiv>&nbsp;</EmptyDiv>;
};

class ImageSimple extends React.Component {
  constructor(props) {
    super(props);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.state = {
      ready: false,
    };
  }

  onVisibilityChange(visible) {
    if (visible) {
      this.setState({ ready: true });
    }
  }

  render() {
    const {
      name,
      alt,
      imageWidth,
      imageHeight,
      alwaysVisible,
      ...otherProps
    } = this.props;
    const { ready } = this.state;
    const heightProp = imageHeight ? `${imageHeight}`.replace('px', '') : 1;
    const widthProp = imageWidth ? `${imageWidth}`.replace('px', '') : 1;
    let imageRequest = imageHeight || imageWidth
      ? getImageRequestName(name, `${widthProp}x${heightProp}`)
      : getImageRequestName(name);
    if (webp || (!getArg('browser') && getArg('isPageSpeed'))) {
      imageRequest = imageHeight || imageWidth
        ? getImageRequestNameWebp(name, `${widthProp}x${heightProp}`)
        : getImageRequestNameWebp(name);
    }
    if (getImageInfo(name)) {
      return (
        <VisibilitySensor onChange={this.onVisibilityChange} active={!ready && !alwaysVisible} partialVisibility>
          <Img imgComponent={NormalImage} src={imageRequest} alt={alt || name} visible={ready || alwaysVisible} {...otherProps} />
        </VisibilitySensor>
      );
    }
    return null;
  }
}

export default ImageSimple;
import React from 'react';
import { Bold, Paragraph } from '../../../components/typography';
import Lukkedage from '../../../components/Lukkedage';

const Aabningstider = () => {
  return (
    <React.Fragment>
      <Paragraph>
        <Bold>Telefon</Bold><br />
        Alle hverdage: 7 - 16<br />
        Akut: Alle dage: 6 - 24
      </Paragraph>
      <Paragraph>
        <Bold>Lager / Butik</Bold><br />
        Alle hverdage 7 - 16
      </Paragraph>
      <Paragraph>
        <Bold>Udbringning og returtagning:</Bold><br />
        Mandag - Søndag
      </Paragraph>
      <Lukkedage />
      <Paragraph marginTop="10px">
        Levering, afhentning, samt ekspedition søn- og helligdage og uden for åbningstid efter særlig aftale mod ekstra betaling.
      </Paragraph>
    </React.Fragment>
  );
};

export default Aabningstider;

import React from "react";
import styled from "styled-components/macro";
import GodkendTabButton from "../layout/MainBestilLayout/GodkendTabButton";
import TabButtonContainer from "../layout/MainBestilLayout/TabButtonContainer";
import { TabButtonWrap, TabFlexDiv } from "../TabsComponents";
import { ImTruck } from "../../../components/Icons";

const IconContainer = styled.div`
  & svg {
    height: 20px;
    width: 20px;

    @media (max-width: 400px) {
      height: 15px;
      width: 15px;
    }
  }
`;

const tabs = [
  {
    id: "kulsyre",
    to: "/bestil/kulsyre",
    title: "Kulsyre",
    mediumTitle: "Kulsyre",
    smallTitle: "Kulsyre",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "levering",
    to: "/bestil/kulsyre/levering",
    title: "Levering",
    mediumTitle: (
      <IconContainer>
        <ImTruck />
      </IconContainer>
    ),
    smallTitle: (
      <IconContainer>
        <ImTruck />
      </IconContainer>
    ),
    showSmall: true,
    showMedium: true,
  },
];

export default ({ activeTab }) => (
  <TabButtonWrap>
    <TabFlexDiv>
      <TabButtonContainer activeTab={activeTab} tabs={tabs} />
    </TabFlexDiv>
    <GodkendTabButton to="/bestil/detaljer" title="Gå til kassen" />
  </TabButtonWrap>
);

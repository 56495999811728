import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import GodkendTabButton from "../layout/MainBestilLayout/GodkendTabButton";
import TabButtonContainer from "../layout/MainBestilLayout/TabButtonContainer";
import { TabFlexDiv, TabButtonWrap } from "../TabsComponents";
import { ImTruck } from "../../../components/Icons";

const IconContainer = styled.div`
  & svg {
    height: 20px;
    width: 20px;

    @media (max-width: 400px) {
      height: 15px;
      width: 15px;
    }
  }
`;

const tabs = [
  {
    id: "kildevand",
    to: "/bestil/flasker-og-daaser/kildevand",
    title: "Kildevand",
    mediumTitle: "Kildevand",
    smallTitle: "Vand",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "sodavand",
    to: "/bestil/flasker-og-daaser/sodavand",
    title: "Sodavand",
    mediumTitle: "Sodavand",
    smallTitle: "Soda",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "juice-og-saft",
    to: "/bestil/flasker-og-daaser/juice-og-saft",
    title: "Juice & Saft",
    mediumTitle: "Juice",
    smallTitle: "Juice",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "oel-og-alkohol",
    to: "/bestil/flasker-og-daaser/oel-og-alkohol",
    title: "Øl & Alkohol",
    mediumTitle: "Alkohol",
    smallTitle: "Øl",
    showSmall: true,
    showMedium: true,
  },
  {
    id: "levering",
    to: "/bestil/flasker-og-daaser/levering",
    title: "Levering",
    mediumTitle: (
      <IconContainer>
        <ImTruck />
      </IconContainer>
    ),
    smallTitle: (
      <IconContainer>
        <ImTruck />
      </IconContainer>
    ),
    showSmall: true,
    showMedium: true,
  },
];

const saedvanligeTab = {
  to: "/bestil/flasker-og-daaser/saedvanlige",
  title: "Sædvanlige",
  mediumTitle: "Ofte",
  smallTitle: "Ofte",
  showSmall: true,
  showMedium: true,
};

const tabsWithSaedvanlige = [saedvanligeTab, ...tabs];

const DefaultTabButtons = connect(({ plejerliste }) => plejerliste)(
  ({ plejerListe, activeTab }) =>
    plejerListe.length > 0 ? (
      <TabButtonContainer tabs={tabsWithSaedvanlige} activeTab={activeTab} />
    ) : (
      <TabButtonContainer tabs={tabs} activeTab={activeTab} />
    )
);

export default ({ activeTab }) => (
  <TabButtonWrap>
    <TabFlexDiv>
      <DefaultTabButtons activeTab={activeTab} />
    </TabFlexDiv>
    <GodkendTabButton to="/bestil/detaljer" title="Gå til kassen" />
  </TabButtonWrap>
);
